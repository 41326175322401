import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserInstitution } from '@tremaze/shared/feature/user/feature/allocation/types';
import { map } from 'rxjs/operators';
import { Department } from '@tremaze/shared/feature/department/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { InstitutionREADDataSource } from '@tremaze/shared/feature/institution/data-access';
import { RemoteCCDepartmentDataSource } from '@tremaze/shared/feature/department/data-access';
import { TremazeHttpResponse } from '@tremaze/shared/util-http/types';

@Injectable({ providedIn: 'root' })
export class RemoteCCUserAllocationDataSource {
  constructor(
    private readonly http: HttpClient,
    private readonly institutionDataSource: InstitutionREADDataSource,
    private readonly departmentDataSource: RemoteCCDepartmentDataSource,
  ) {}

  getUserInstitutionsForUser(userId: string): Observable<UserInstitution[]> {
    return this.http
      .get<unknown[]>(`/users/${userId}/institutions`)
      .pipe(map((r) => r.map(UserInstitution.deserialize)));
  }

  getDepartmentsForUser(userId: string): Observable<Department[]> {
    return this.http
      .get<unknown[]>(`/users/${userId}/departments`)
      .pipe(map((d) => d.map(Department.deserialize)));
  }

  getAvailableInstitutions(filterValue?: string): Observable<Institution[]> {
    return this.institutionDataSource
      .getPaginated(
        { filter: { filterValue, sort: 'name', sortDirection: 'asc' } },
        ['USER_WRITE', 'MANAGE_REFERENCE_CLIENTS'],
      )
      .pipe(map((r) => r.content));
  }

  getInstitutionDepartments(
    instId: string,
    filterValue?: string,
  ): Observable<Department[]> {
    return this.departmentDataSource
      .getPaginated({
        instIds: [instId],
        filter: { filterValue, sort: 'name', sortDirection: 'asc' },
      })
      .pipe(map((r) => r.content));
  }

  /***
   * Saves whole allocation settings at once.
   * TODO: Error handling
   * @param userId
   * @param userInstitutionsDepartments
   */
  saveAllocation(
    userId: string,
    userInstitutionsDepartments: {
      userInstitution: UserInstitution;
      departments?: Department[];
    }[],
  ): Observable<boolean> {
    return this.http
      .post<TremazeHttpResponse<unknown>>(
        `/institutions/users/${userId}`,
        userInstitutionsDepartments.map((d) => {
          return {
            instId: d.userInstitution.institution.id,
            instRoleIds: d.userInstitution?.roles?.map((r) => r.id),
            departmentIds: d?.departments?.map((d) => d.id),
          };
        }),
      )
      .pipe(map((r) => r.status === 'SUCCESS'));
  }
}
