export const lorem200 =
  'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A asperiores consequatur, cupiditate debitis delectus deleniti, dicta eius enim est et expedita ipsum molestiae mollitia nam, quaerat repellat tempore unde voluptate! Debitis eaque molestiae quo? Commodi, eaque esse fugit iure iusto modi nemo nesciunt quia vel veniam! Ab at aut consectetur consequatur deleniti earum enim eveniet exercitationem hic illum incidunt ipsa iste iure, laborum mollitia necessitatibus nemo nisi obcaecati odit optio quaerat, quod repellat sapiente vitae voluptatibus. Accusamus at autem corporis dignissimos ducimus eius facere fugiat ipsam maiores minima molestiae nemo odio officia placeat quia quis quisquam recusandae sint velit, voluptatem? Architecto, blanditiis corporis deleniti dicta, dolor doloremque earum ipsum modi nam nulla recusandae sunt tempora tempore totam voluptates! Aliquid atque autem consequatur cum deleniti dolorum enim eveniet facilis harum impedit iure, mollitia neque nobis nostrum, omnis optio pariatur perferendis qui quod recusandae reiciendis repellat sequi tenetur, voluptas voluptate? Autem commodi consequatur, dignissimos distinctio dolore eaque et in iste iusto laborum magni neque nisi nostrum nulla recusandae repellendus sed! Aut consectetur ipsa ipsum laudantium maiores nesciunt officiis perspiciatis placeat repellat, sequi sint soluta voluptatibus. Architecto aspernatur eum fuga, illum repellendus velit voluptatibus. Accusamus animi aperiam consectetur distinctio doloremque nulla similique ullam?';

export const longHtml = `
    <h1>Welcome to Rich Text in HTML</h1>
    <p>This is an example of a rich text document created using HTML. It includes various elements to demonstrate the versatility of structured content.</p>

    <h2>Headings and Paragraphs</h2>
    <p>Headings provide structure to your content, while paragraphs are used for regular text. For example:</p>
    <h3>Subheading Example</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

    <h2>Lists</h2>
    <p>You can use ordered and unordered lists:</p>
    <ul>
        <li>First item</li>
        <li>Second item</li>
        <li>Third item</li>
    </ul>
    <p>Or an ordered list:</p>
    <ol>
        <li>Step one</li>
        <li>Step two</li>
        <li>Step three</li>
    </ol>

    <h2>Blockquotes and Code</h2>
    <blockquote>
        "The only way to do great work is to love what you do." - Steve Jobs
    </blockquote>
    <p>You can also include inline <code>code</code> or block code:</p>
    <pre><code>function greet() {
    console.log('Hello, World!');
}</code></pre>

    <h2>Links</h2>
    <p>Here is a link to <a href="https://developer.mozilla.org/en-US/docs/Web/HTML">HTML Documentation</a>.</p>

    <h2>Conclusion</h2>
    <p>HTML allows for creating rich and engaging text content. By using different elements, you can structure your text and make it visually appealing.</p>
`;
