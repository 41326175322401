import { PageGenerator } from '../helpers';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { CaseStatus } from '@tremaze/case-types';
import { coloredBadgeColorMap } from '@tremaze/colored-badge';

const statusNames = [
  'Anmeldung',
  'Zugewiesen',
  'In Beraetung',
  'abgeschlossen gem. Ziel',
  'abgeschlossen abweichend',
  'Kein Fall',
];

class CaseStatusFixturePageGenerator extends PageGenerator<CaseStatus> {
  override generateByIndex(index: number): CaseStatus {
    return CaseStatusFixtures.generateCaseStatus(index);
  }
}

export abstract class CaseStatusFixtures {
  static generateCaseStatus = (id: number): CaseStatus => ({
    name: statusNames[id % statusNames.length],
    id: `${id}`,
    color: Object.keys(coloredBadgeColorMap)[
      id % Object.keys(coloredBadgeColorMap).length
    ] as keyof typeof coloredBadgeColorMap,
  });

  private static _caseStatusStubPageGenerator =
    new CaseStatusFixturePageGenerator();

  static generateCaseStatusPage = (
    options: DataSourceMethodsPaginatedOptions,
  ) => CaseStatusFixtures._caseStatusStubPageGenerator.generatePage(options);

  static readonly caseStatus1: CaseStatus =
    CaseStatusFixtures.generateCaseStatus(1);
  static readonly caseStatus2: CaseStatus =
    CaseStatusFixtures.generateCaseStatus(2);
  static readonly caseStatus3: CaseStatus =
    CaseStatusFixtures.generateCaseStatus(3);
  static readonly caseStatus4: CaseStatus =
    CaseStatusFixtures.generateCaseStatus(4);
  static readonly caseStatus5: CaseStatus =
    CaseStatusFixtures.generateCaseStatus(5);

  static readonly caseStatuses = [
    CaseStatusFixtures.caseStatus1,
    CaseStatusFixtures.caseStatus2,
    CaseStatusFixtures.caseStatus3,
    CaseStatusFixtures.caseStatus4,
    CaseStatusFixtures.caseStatus5,
  ];
}
