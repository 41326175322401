import { Observable } from 'rxjs';

export abstract class NumberRangeDataSource {
  abstract getNumberRangeSettingForInstitution(
    institutionId: string,
  ): Observable<{ pattern: string; firstNumber: string } | null>;

  abstract saveNumberRangeSettingForInstitution(
    institutionId: string,
    numberRange: { pattern: string; firstNumber: string },
  ): Observable<void>;
}
