import {
  CustomForm,
  CustomFormSubmission,
  CustomFormVersion,
} from '@tremaze/shared/feature/custom-forms/types';
import { filter, map, Observable, tap } from 'rxjs';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { CustomFormFillOutDataSource } from '@tremaze/shared/feature/custom-forms/feature/fill-out/data-access';

@Injectable()
export class RemoteCustomFormFillOutDataSource extends CustomFormFillOutDataSource {
  constructor(private readonly _http: HttpClient) {
    super();
  }

  getFormVersion(id: string): Observable<CustomFormVersion> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._http
      .get(`/forms/versions/${id}`)
      .pipe(map((d) => CustomFormVersion.deserialize(d)!));
  }

  getForm(id: string): Observable<CustomForm> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._http
      .get(`/forms/${id}`)
      .pipe(map((d) => CustomForm.deserialize(d)!));
  }

  getSubmission(id: string): Observable<CustomFormSubmission> {
    return this._http
      .get(`/forms/submissions/${id}`)
      .pipe(map(CustomFormSubmission.deserialize));
  }

  uploadFiles(
    files: FileList,
    config?: {
      onProgress?: (progress: number) => void;
    },
  ): Observable<FileStorage[]> {
    const endpoint = '/public/files/upload/form';
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) {
        continue;
      }
      formData.append('files', file);
    }
    return this._http
      .post(endpoint, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        tap((event) => {
          if (
            event.type === HttpEventType.UploadProgress &&
            config?.onProgress
          ) {
            config.onProgress(
              Math.round((100 * event.loaded) / (event.total ?? 1)),
            );
          }
        }),
        filter((event) => event.type === HttpEventType.Response),
        tap(() => config?.onProgress?.(100)),
        map((event) =>
          ((event as HttpResponse<any>).body['object'] as Array<any>).map(
            FileStorage.deserialize,
          ),
        ),
      );
  }
}

export const provideRemoteCustomFormFillOutDataSource = () => ({
  provide: CustomFormFillOutDataSource,
  useClass: RemoteCustomFormFillOutDataSource,
});
