import { ColoredBadgeColor } from '@tremaze/colored-badge';

export type CaseStatus = {
  readonly id: string;
  readonly name: string;
  readonly color: ColoredBadgeColor;
};

export function deserializeCaseStatus(caseStatus: any): CaseStatus {
  return {
    id: caseStatus.id,
    name: caseStatus.name,
    color: caseStatus.color.toLowerCase(),
  };
}
