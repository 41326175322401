import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  distinctUntilChanged,
  merge,
  Observable,
  startWith,
  Subject,
} from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { MatInput } from '@angular/material/input';
import { Failure } from '@tremaze/shared/util-error';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

@Component({
  selector: 'tremaze-autocomplete',
  template: `
    <input
      type="text"
      [placeholder]="placeholder"
      matInput
      autocomplete="off"
      [matAutocompleteDisabled]="disabled"
      [formControl]="inputControl"
      [matAutocomplete]="auto"
      (blur)="onBlur()"
    />
    <button
      class="autocomplete-clear-btn"
      mat-icon-button
      matSuffix
      (click)="onClickClearButton()"
      *ngIf="!empty && !disabled"
    >
      <span class="lnr lnr-cross"></span>
    </button>
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
      [displayWith]="getOptionLabel"
    >
      <mat-option *ngIf="showAddOption" (click)="onClickAddOption($event)"
        >Hinzufügen
      </mat-option>

      <ng-container *ngIf="data$ | async as data">
        <ng-container *ngIf="data.length > 0; else noResults">
          <mat-option *ngFor="let item of data" [value]="item">
            <div class="d-flex center-children-vertically">
              <tremaze-circle-avatar
                *ngIf="getOptionAvatar"
                style="margin-right: 8px;"
                [radius]="15"
                [file]="getOptionAvatar(item)"
                [canShowPreviewOverlay]="false"
                [fallbackInitials]="getOptionInitials?.(item)"
              ></tremaze-circle-avatar>
              <div>
                <div>
                  <b class="chip-title">{{ getOptionLabel(item) }}</b>
                </div>
                <div *ngIf="getOptionSubtitle" class="sub-label">
                  <small>{{ getOptionSubtitle(item) }}</small>
                </div>
              </div>
            </div>
          </mat-option>
        </ng-container>
      </ng-container>

      <ng-template #noResults>
        <mat-option disabled>
          <div class="d-flex center-children-vertically">
            <div>
              <div>
                <b>Keine Ergebnisse</b>
              </div>
            </div>
          </div>
        </mat-option>
      </ng-template>
    </mat-autocomplete>
  `,
  styles: [
    `
      :host {
        display: flex;
        padding-right: 5px;
      }
    `,
  ],
  providers: [
    { provide: MatFormFieldControl, useExisting: AutocompleteComponent },
  ],
})
export class AutocompleteComponent<T>
  implements
    OnInit,
    OnDestroy,
    DoCheck,
    MatFormFieldControl<T>,
    ControlValueAccessor
{
  static nextId = 0;
  data$: Observable<T[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  error = false;
  errorState = false;
  controlType?: string;
  autofilled?: boolean;
  stateChanges = new Subject<void>();
  defaultPlaceholder: string;
  @HostBinding() id = `generic-selector-${AutocompleteComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Input() dataSource: (filterVal: string) => Observable<Failure | T[]>;
  @Input() createItem: (itemName: string) => Observable<Failure | T>;
  inputControl = new UntypedFormControl({
    value: null,
    disabled: this.disabled,
  });
  @Output() selectionChange = new EventEmitter<T | T[]>();
  @Output() readonly clickAddOption = new EventEmitter();
  @ViewChild(MatInput) private input: MatInput;
  private reload = new Subject();
  private onTouchedCallback: () => void;
  private onChangeCallback: (_: any) => void;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _showAddOption: boolean;

  @Input()
  get showAddOption(): boolean {
    return this._showAddOption;
  }

  set showAddOption(value: boolean) {
    this._showAddOption = coerceBooleanProperty(value);
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next(null);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return (
      this._required ||
      this.ngControl?.control?.hasValidator(Validators.required)
    );
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled || this.ngControl?.disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (!value) {
      this.inputControl?.enable();
    } else {
      this.inputControl?.disable();
    }
    this.stateChanges.next(null);
  }

  private _value: T | null;

  get value() {
    return this._value;
  }

  @Input()
  set value(v: T | null) {
    this._value = v;
    this.inputControl.setValue(v, { emitEvent: false });
  }

  get shouldLabelFloat() {
    return this.input?.shouldLabelFloat || !this.empty;
  }

  get empty() {
    return !this.value;
  }

  private _focused = false;

  get focused(): boolean {
    return this._focused && !this.disabled;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  private _reportChange() {
    this.onChangeCallback?.(this.value);
    this.selectionChange.emit(this.value);
  }

  onClickClearButton() {
    this.value = null;
    this.inputControl.patchValue(null);
    this._reportChange();
  }

  onClickAddOption(event: Event) {
    event.stopPropagation();
    this.clickAddOption.emit();
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this._reportChange();
  }

  @Input() getOptionLabel: (val: T) => string = (val) =>
    !val ? '' : (val as any)?.name;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() getOptionAvatar?: (val: T) => FileStorage;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() getOptionInitials?: (val: T) => string;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() getOptionSubtitle?: (val: T) => string;

  @Input() comparator: (val1: T, val2: T) => boolean = (v1, v2) =>
    (v1 as any)?.id === (v2 as any)?.id;

  onContainerClick(event: MouseEvent): void {
    this.input.onContainerClick();
    event.stopPropagation();
    event.preventDefault();
  }

  public triggerReload() {
    this.reload.next(null);
  }

  ngOnInit() {
    if (this.value) {
      this.inputControl.setValue(this.value);
    }
    if (!this.dataSource) {
      throw new Error('Autocomplete must be provided a dataSource Input');
    }
    if (this.disabled) {
      this.inputControl.disable();
    }
    this.placeholder = this.defaultPlaceholder || 'Bitte auswählen';
    this.data$ = merge(
      this.reload,
      this.inputControl.valueChanges.pipe(
        tap((r) => {
          if (typeof r === 'string' && r.length === 0) {
            this.value = null;
          }
        }),
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
      ),
    ).pipe(
      map(() =>
        typeof this.inputControl.value === 'string'
          ? this.inputControl.value.trim()
          : '',
      ),
      switchMap((f) => {
        return this.dataSource(f).pipe(
          map((r) => {
            return r instanceof Failure ? [] : r;
          }),
        );
      }),
    );
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.reload.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
      if (this.disabled !== this.inputControl?.disabled) {
        if (this.disabled) {
          this.inputControl.disable();
        } else {
          this.inputControl.enable();
        }
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (this.value !== obj) {
      this.value = obj;
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onBlur() {
    this.onTouchedCallback?.();
    this.focused = false;
    this.stateChanges.next(null);
  }
}
