import { PageGenerator } from '../helpers';
import { CaseStatisticIdentifier } from '@tremaze/case-types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';

const caseStatisticIdentifierNames = [
  '$ 28 SGB VIII',
  '$ 41 SGB VIII',
  '$ 17 SGB VIII',
  'Paar EFL',
  'Leben EFL',
  '$ 2 SchKG',
  '$ 5/6 SchKG',
  'Trauma ohne FL',
  'Sex. Gew.',
  'Fachkräfte',
];

function generateCaseStatisticIdentifierName(i: number): string {
  return caseStatisticIdentifierNames[i % caseStatisticIdentifierNames.length];
}

class CaseStatisticIdentifierFixturePageGenerator extends PageGenerator<CaseStatisticIdentifier> {
  override generateByIndex(index: number): CaseStatisticIdentifier {
    return CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(
      index,
    );
  }
}

export abstract class CaseStatisticIdentifierFixtures {
  static generateCaseStatisticIdentifier = (
    id: number,
  ): CaseStatisticIdentifier => ({
    name: generateCaseStatisticIdentifierName(id),
    id: `${id}`,
  });

  private static _caseStatisticIdentifierStubPageGenerator =
    new CaseStatisticIdentifierFixturePageGenerator();

  static generateCaseStatisticIdentifierPage = (
    options: DataSourceMethodsPaginatedOptions,
  ) =>
    CaseStatisticIdentifierFixtures._caseStatisticIdentifierStubPageGenerator.generatePage(
      options,
    );

  static readonly caseStatisticIdentifier1: CaseStatisticIdentifier =
    CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(1);
  static readonly caseStatisticIdentifier2: CaseStatisticIdentifier =
    CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(2);
  static readonly caseStatisticIdentifier3: CaseStatisticIdentifier =
    CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(3);
  static readonly caseStatisticIdentifier4: CaseStatisticIdentifier =
    CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(4);
  static readonly caseStatisticIdentifier5: CaseStatisticIdentifier =
    CaseStatisticIdentifierFixtures.generateCaseStatisticIdentifier(5);

  static readonly caseStatisticIdentifiers = [
    CaseStatisticIdentifierFixtures.caseStatisticIdentifier1,
    CaseStatisticIdentifierFixtures.caseStatisticIdentifier2,
    CaseStatisticIdentifierFixtures.caseStatisticIdentifier3,
    CaseStatisticIdentifierFixtures.caseStatisticIdentifier4,
    CaseStatisticIdentifierFixtures.caseStatisticIdentifier5,
  ];
}
