import {
  CustomForm,
  CustomFormSubmission,
  CustomFormVersion,
} from '@tremaze/shared/feature/custom-forms/types';
import { Observable } from 'rxjs';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

export abstract class CustomFormFillOutDataSource {
  abstract getFormVersion(id: string): Observable<CustomFormVersion>;

  abstract getForm(id: string): Observable<CustomForm>;

  abstract getSubmission(id: string): Observable<CustomFormSubmission>;

  abstract uploadFiles(
    files: FileList,
    config?: {
      onProgress?: (progress: number) => void;
    },
  ): Observable<FileStorage[]>;
}
