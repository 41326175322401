import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSourceImpl,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { ExternalResource } from '@tremaze/external-resource-types';
import { inject, Injectable } from '@angular/core';
import { ExternalResourceDataSource } from './external-resource-data-source';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';

type InputDTO = {
  title: string;
  url: string;
  userTypes: string;
  imageId?: string;
  openInInAppWebView?: boolean;
};

function externalResourceToInputDTO(
  externalResource: ExternalResource,
): InputDTO {
  return {
    title: externalResource.title,
    url: externalResource.url,
    userTypes: externalResource.userTypes,
    imageId: externalResource.image?.id,
    openInInAppWebView: externalResource.openInInAppWebView,
  };
}

@Injectable()
export class ExternalResourceDataSourceImpl
  extends DefaultCRUDDataSourceImpl<ExternalResource>
  implements ExternalResourceDataSource
{
  protected readonly deserializer = ExternalResource.deserialize;
  protected readonly js = inject(JsonSerializer);
  protected readonly http = inject(HttpClient);
  protected override controller = 'externalResources';

  override create(
    i: ExternalResource,
    options?: DataSourceMethodsCreateOptions<ExternalResource>,
  ): Observable<ExternalResource> {
    return super.create(externalResourceToInputDTO(i) as any, options);
  }

  override edit(
    i: ExternalResource,
    options?: DataSourceMethodsEditOptions<ExternalResource>,
  ): Observable<ExternalResource> {
    return super.edit(externalResourceToInputDTO(i) as any, {
      ...options,
      controller: `externalResources/${i.id}`,
    });
  }

  getPaginatedForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ExternalResource>> {
    return DefaultDataSourceMethods.getPaginated(
      this.http,
      `institutions/${instId}/externalResources`,
      this.deserializer,
      options,
    );
  }

  createForInstitution(
    instId: string,
    entity: ExternalResource,
  ): Observable<ExternalResource> {
    const input = externalResourceToInputDTO(entity);
    return DefaultDataSourceMethods.create(
      this.http,
      `institutions/${instId}/externalResources`,
      this.deserializer,
      this.js,
      input as any,
    );
  }
}

export const provideExternalResourceDataSource = () => ({
  provide: ExternalResourceDataSource,
  useClass: ExternalResourceDataSourceImpl,
});
