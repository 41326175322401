import { DataSourceMethodsBaseOptions } from '@tremaze/shared/util-http';
import { EventScopeOptions } from './shared-feature-event-data-access';
import { Price, TremazeEvent } from '@tremaze/shared/feature/event/types';
import { Input } from './types';

export function joinScopeOptions<T extends DataSourceMethodsBaseOptions>(
  options?: T & EventScopeOptions,
): (T & EventScopeOptions) | undefined {
  if (!options) {
    return undefined;
  }
  const query = options?.q ?? {};
  if (options.scope) {
    query['eventScope'] = options.scope;
  }
  return { ...options, q: query };
}

export function eventToInputDTO(
  i: TremazeEvent,
  hasVideoMeeting: boolean,
): Input {
  const address = { ...(i.address ?? {}) } as any;

  const temporalRegistrationRestriction = {
    register: null,
    cancel: null,
  };

  if (i.temporalRegistrationRestriction) {
    if (i.temporalRegistrationRestriction.register) {
      temporalRegistrationRestriction.register = {
        duration: i.temporalRegistrationRestriction.register.duration.inMinutes,
        relation: i.temporalRegistrationRestriction.register.relation,
      };
    }
    if (i.temporalRegistrationRestriction.cancel) {
      temporalRegistrationRestriction.cancel = {
        duration: i.temporalRegistrationRestriction.cancel.duration.inMinutes,
        relation: i.temporalRegistrationRestriction.cancel.relation,
      };
    }
  }

  delete address.country;

  let payload: Input = {
    endDate: i.endDate?.toISOString(),
    startDate: i.startDate.toISOString(),
    registrationNecessary: i.registrationNecessary === true,
    prices: i.prices.filter((p) => p.value > 0),
    price: Price.findEuroPriceRepresented(i.prices),
    maxMember: i.maxMember,
    minAge: i.minAge,
    maxAge: i.maxAge,
    highlight: i.highlight === true,
    address,
    description: i.description,
    genderIds: i.gender?.map((g) => g.id),
    instIds: i.institutions?.map((inst) => inst.id),
    departmentIds: i.departments?.map((d) => d.id),
    titleImageId: i.titleImage?.id ?? null,
    name: i.name,
    categoryId: i.category?.id ?? null,
    userIds: i.users?.map((u) => u.id),
    visibleForFamily: i.visibleForFamily ?? false,
    public: i.isPublic,
    userTypeIds: i.userTypes?.map((u) => u.id),
    organizerId: i.organizer?.id ?? null,
    workshop: i.workshop,
    notifications: i.notifications,
    specializationIds: i.specializations?.map((s) => s.id),
    hideWhenFull: i.hideWhenFull,
    contextInstitutionId: i.contextInstitution?.id ?? null,
    temporalRegistrationRestriction,
    approvalId: i.approval?.id,
    partialBudgetId: i.partialBudget?.id,
    institutionCaseId: i.institutionCase?.id,
  };

  if (i.schedule) {
    payload = {
      ...payload,
      schedule: {
        repeatNumber: i.schedule.repeatNumber,
        repeatDays: i.schedule.repeatDays,
        startDate: (i.schedule.startDate ?? i.startDate)?.toJSON() as any,
        endDate: i.schedule.endDate?.toJSON() as any,
        repeatEvery: i.schedule.repeatEvery,
        allowedRegistrationScope: i.schedule.allowedRegistrationScope,
      },
    };
  }

  return payload;
}
