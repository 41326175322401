import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SharedFileStorageUiCircleAvatarModule } from '@tremaze/shared/feature/file-storage/ui/circle-avatar';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    SharedFileStorageUiCircleAvatarModule,
  ],
  declarations: [AutocompleteComponent],
  exports: [AutocompleteComponent],
})
export class SharedUiAutocompleteModule {}
