import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Subject } from 'rxjs';

// This does not work reliably with async pipe due to content projection
// TODO: Change this from using content projection to using input properties
//  i.e. <tremaze-initials-circle [initials]="initials"></tremaze-initials-circle>

@Component({
  selector: 'tremaze-initials-circle',
  template: `<ng-content></ng-content> `,
  styles: [
    `
      :host {
        border-radius: 50%;
        font-weight: bold;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 2.2em;
        width: 2.2em;
        color: white;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'tremaze-initials-circle',
  },
})
export class InitialsCircleComponent implements AfterViewInit {
  @Input() radius = 20;
  readonly colorRGBString$ = new Subject<string>();

  constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
  ) {}

  @HostBinding('style.fontSize')
  @HostBinding('style.lineHeight')
  get fontSize() {
    return this.radius * 0.9 + 'px';
  }

  @HostBinding('style.width')
  @HostBinding('style.height')
  @HostBinding('style.minWidth')
  @HostBinding('style.maxWidth')
  @HostBinding('style.minHeight')
  @HostBinding('style.maxHeight')
  get dimensions() {
    return `${this.radius * 2}px`;
  }

  @Input() color: ThemePalette;

  get colorRGB() {
    return pastel_colour(this.elementRef.nativeElement.innerText);
  }

  @HostBinding('style.backgroundColor')
  get backgroundColor() {
    return `rgb(${this.colorRGB.join(',')})`;
  }

  ngAfterViewInit() {
    this.colorRGBString$.next(this.backgroundColor);
    this._cdRef.detectChanges();
  }
}

function pastel_colour(input_str?: string) {
  if (input_str === undefined) {
    return [128, 128, 128];
  }
  //TODO: adjust base colour values below based on theme
  const baseRed = 128;
  const baseGreen = 128;
  const baseBlue = 128;

  //lazy seeded random hack to get values from 0 - 256
  //for seed just take bitwise XOR of first two chars
  // tslint:disable-next-line:no-bitwise
  let seed = input_str.charCodeAt(0) ^ input_str.charCodeAt(1);
  const rand_1 = Math.abs(Math.sin(seed++) * 10000) % 256;
  const rand_2 = Math.abs(Math.sin(seed++) * 10000) % 256;
  const rand_3 = Math.abs(Math.sin(seed++) * 10000) % 256;

  //build colour
  const red = Math.round((rand_1 + baseRed) / 2);
  const green = Math.round((rand_2 + baseGreen) / 2);
  const blue = Math.round((rand_3 + baseBlue) / 2);

  return [red, green, blue];
}
