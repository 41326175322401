import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import {
  Approval,
  ApprovalStatus,
  PartialBudget,
} from '@tremaze/shared/feature/approval/types';
import { EventStatus, TremazeEvent } from '@tremaze/shared/feature/event/types';
import { DateRange } from '@tremaze/shared/util-date';

export abstract class ApprovalDataSource {
  abstract getPaginatedApprovals(
    options: DataSourceMethodsPaginatedOptions & {
      includeSubServices?: boolean;
      statusFilter?: ApprovalStatus[];
      userIds?: string[];
      instIds?: string[];
      serviceId?: string;
    },
  ): Observable<Pagination<Approval>>;

  abstract getApprovalById(id: string): Observable<Approval>;

  abstract getPartialBudgetById(id: string): Observable<PartialBudget>;

  abstract deleteApproval(id: string): Observable<boolean>;

  abstract createApproval(approval: Partial<Approval>): Observable<Approval>;

  abstract updateApproval(approval: Approval): Observable<Approval>;

  abstract getEventsForApproval(
    approvalId: string,
    filter?: {
      eventStatuses?: EventStatus[];
      filterValue?: string;
      dateRange?: DateRange;
    },
  ): Observable<TremazeEvent[]>;

  abstract getEventById(eventId: string): Observable<TremazeEvent>;

  abstract isVivendiIdUsed(vivendiId: string): Observable<boolean>;
}
