import { SharedFileStorageUiSingleFileDisplayModule } from '@tremaze/shared/feature/file-storage/ui/single-file-display';
import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSelectorInputComponent } from './file-selector-input.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { SharedFeatureFileStorageFeatureImageCropperModule } from '@tremaze/shared/feature/file-storage/feature/image-cropper';
import { SharedFileStorageUiFilePreviewOverlayModule } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';
import { SharedFeatureInstitutionFeatureSelectionModule } from '@tremaze/shared/feature/institution/feature/selection';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    SharedFileStorageUiSingleFileDisplayModule,
    MatTooltipModule,
    MatIconModule,
    SharedFileStorageUiFilePreviewOverlayModule,
    SharedFeatureFileStorageFeatureImageCropperModule,
    SharedPermissionUiDirectivesModule,
    SharedFeatureInstitutionFeatureSelectionModule,
  ],
  declarations: [FileSelectorInputComponent],
  exports: [FileSelectorInputComponent],
})
export class SharedFeatureFileStorageUiFileSelectorInputModule {}
