import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  output,
  Output,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DataTableStore } from './data-table.store';
import { rowAnimation } from '@tremaze/shared/sorted-filtered-paginated-table/ui';
import { DataTableColumnDirective } from './data-table-column.directive';
import { Sort, SortDirection } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, firstValueFrom, isObservable, Observable, of, Subject } from 'rxjs';
import { DataTableActionsService } from './data-table-actions.service';
import { IdObject } from '@tremaze/shared/util/id-object';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { PreActionsDirective } from './pre-actions.directive';
import { DataTablePermissionConfig, DataTableTheme } from '@tremaze/shared/ui/data-table/types';
import { PrivilegeName, TzPermissionRequest } from '@tremaze/shared/permission/types';
import { FilterConfig } from './data-table-parts/data-table-search-bar.component';
import { DataTableActionDirective } from './data-table-action.directive';
import { deepEqual } from '@tremaze/shared/util-utilities';
import { DataTableCustomFilterDirective } from './data-table-custom-filter.directive';
import { SelectionModel } from '@angular/cdk/collections';
import { ensureObservable, filterNotNullOrUndefined, filterTrue } from '@tremaze/shared/util/rxjs';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { BulkActionService } from '@tremaze/bulk-action';
import { PermissionCheckService } from '@tremaze/shared/permission/services';

/**
 * How to use this class:
 * 1. Provide a DataTableDataSource for the DataTableStore
 * 2. Provide Column Definitions with content projection by using the DataTableColumnDirective for each column
 * 3. Optional: Provide a DataTableActionsService to the component
 * 4. Optional: Provide leading Actions with content projection by using the PreActionsDirective for the action-area
 */

const PAGE_SIZES: PAGE_SIZE[] = [1, 5, 10, 20, 50, 100];

export type PAGE_SIZE = 1 | 5 | 10 | 20 | 50 | 100 | 1000;

interface QueryParams {
  [key: string]: string | string[];
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tremaze-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataTableStore],
  animations: [rowAnimation],
})
export class DataTableComponent<T extends IdObject>
  implements OnInit, OnDestroy
{
  private readonly _store = inject(DataTableStore<T>);
  private readonly _confirmationService = inject(ConfirmationService);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _actionsService? = inject(DataTableActionsService, {
    optional: true,
  });
  private readonly _bulkActionsService = inject(BulkActionService);
  private readonly _permissionCheckService = inject(PermissionCheckService);

  @Input({ transform: booleanAttribute }) enableDefaultBulkActions = true;

  get defaultBulkActionsAvailable(): boolean {
    return (
      this.enableDefaultBulkActions &&
      !!this._store.dataSource?.deleteById &&
      !!this._store.dataSource?.getFreshById
    );
  }

  readonly initialSort$: Observable<Sort | null> =
    this._store.initialSort$.pipe(
      map((r) => {
        if (r) {
          return r;
        }
        if (this.sortStart && this.sortStartDirection) {
          return {
            active: this.sortStart,
            direction: this.sortStartDirection,
          };
        }
        return null;
      }),
    );

  @Output() readonly filterReset = new EventEmitter<void>();
  readonly elementCreated = output<T>();

  get identifier(): string {
    return this._identifier;
  }

  @Input()
  set identifier(value: string | undefined | null) {
    this._identifier = value;
    this._store.setId(value);
  }

  private _identifier?: string;

  get queryParams(): QueryParams {
    return this._queryParams;
  }

  @Input()
  set queryParams(value: QueryParams) {
    if (deepEqual(value, this._queryParams)) {
      return;
    }
    this._queryParams = value;
    if (this._storeInitialized) {
      this._store.queryParametersUpdate(value);
    }
  }

  private _hideCreateButton = false;

  @Output() readonly rowClick = new EventEmitter<T>();
  @Output() readonly rowDoubleClick = new EventEmitter<T>();

  @Input()
  get hideCreateButton(): boolean {
    return this._hideCreateButton || !this._actionsService?.create;
  }

  set hideCreateButton(value: boolean) {
    this._hideCreateButton = coerceBooleanProperty(value);
  }

  private _hideEditButton = false;

  @Input()
  get hideEditButton(): boolean {
    return this._hideEditButton || !this._actionsService?.edit;
  }

  set hideEditButton(value: boolean) {
    this._hideEditButton = coerceBooleanProperty(value);
  }

  private _hideDeleteButton = false;

  @Input()
  get hideDeleteButton(): boolean {
    return this._hideDeleteButton === true;
  }

  set hideDeleteButton(value: boolean) {
    this._hideDeleteButton = coerceBooleanProperty(value);
  }

  @Input() initialCreateValue?: Partial<T>;

  get groupActionsIntoMenu(): boolean {
    return false;
  }

  get showActionsDense(): boolean {
    return !this.preActions && this.groupActionsIntoMenu;
  }

  private readonly _selection$ = new BehaviorSubject(
    new SelectionModel<T>(false, []),
  );

  get selection$(): Observable<SelectionModel<T>> {
    return this._selection$;
  }

  readonly selectionChange = outputFromObservable(
    this.selection$.pipe(switchMap((s) => s.changed)),
  );

  readonly amountSelected$ = this.selection$.pipe(
    switchMap((s) =>
      s.changed.pipe(
        startWith(null),
        map(() => s.selected.length),
      ),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly hasSelection$ = this.selection$.pipe(
    switchMap((s) => {
      return s.changed.pipe(
        startWith(null),
        map(() => s.hasValue()),
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly isAllSelected$ = this.selection$.pipe(
    switchMap((selection) => {
      return combineLatest([
        selection.changed.pipe(startWith(null)),
        this.data$.pipe(tap(() => this._selection$.value.clear())),
        this.hasSelection$,
      ]).pipe(
        map(([_, data, hasSelection]) => {
          if (!hasSelection) {
            return false;
          }
          let totalSelectable = 0;
          for (const row of data) {
            if (this.enableSelectionForRow(row)) {
              totalSelectable++;
            }
          }
          return totalSelectable === selection.selected.length;
        }),
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly selectionIsIndeterminate$ = combineLatest([
    this.isAllSelected$,
    this.hasSelection$,
  ]).pipe(
    map(([isAllSelected, hasSelection]) => !isAllSelected && hasSelection),
  );

  private _enableSelection = false;

  get enableSelection(): boolean {
    return this._enableSelection;
  }

  @Input({ transform: booleanAttribute })
  set enableSelection(value: any) {
    this._enableSelection = coerceBooleanProperty(value);
  }

  @Input({ transform: booleanAttribute }) set selectMultiple(value: boolean) {
    this._selection$.next(new SelectionModel<T>(value, []));
  }

  @Input() canSelectRowFn?: (row: T) => boolean;

  @Input() caption: string | null;
  @ContentChildren(DataTableColumnDirective)
  columnDefinitions: QueryList<DataTableColumnDirective>;
  @ContentChildren(DataTableActionDirective)
  readonly actionDefinitions: QueryList<DataTableActionDirective>;
  @ContentChild(PreActionsDirective, { read: PreActionsDirective })
  preActions: PreActionsDirective;
  @ContentChildren(DataTableCustomFilterDirective)
  readonly customFilterDefinitions: QueryList<DataTableCustomFilterDirective>;
  @Input() sortStart?: string;
  @Input() sortStartDirection: SortDirection = 'asc';
  @Input() initialPageSize: PAGE_SIZE = 20;
  @Input() filterFields?: string[];
  private _queryParams?: QueryParams;
  @Input() permissionConfig?: DataTablePermissionConfig<T> | null;
  readonly pageSizes = PAGE_SIZES;
  @Input() readonly theme?: DataTableTheme;
  @Input() institutionFilterPrivilege?: PrivilegeName[];
  @Input() departmentFilterPrivilege?: PrivilegeName[];
  @Input() userFilterPrivilege?: PrivilegeName[];
  @Input() pluckItemNameFn?: (row: T) => string;
  private destroyed$ = new Subject();
  private _true$ = of(true).pipe(
    take(1),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  private _storeInitialized = false;

  _idFilter?: string;

  get idFilter(): string {
    return this._idFilter;
  }

  @Input()
  set idFilter(value: string) {
    this._idFilter = value;
    if (this._storeInitialized) {
      this._store.patchState({ idEndpoint: '/' + value });
    }
  }

  readonly hideSearchBar = input(false);

  get filterConfig(): FilterConfig {
    return {
      hideFilter: this.hideFilter,
      enableInstitutionFilter: this.enableInstitutionFilter,
      enableDepartmentFilter: this.enableDepartmentFilter,
      enableUserFilter: this.enableUserFilter,
      filterFields: this.filterFields,
      institutionFilterPrivilege: this.institutionFilterPrivilege,
      departmentFilterPrivilege: this.departmentFilterPrivilege,
      userFilterPrivilege: this.userFilterPrivilege,
    };
  }

  private _deleteIconButtonIconName =
    this.theme?.deleteIconName ?? 'lnr lnr-trash2';

  get deleteIconButtonIconName(): string {
    return this._deleteIconButtonIconName;
  }

  private _editIconButtonIconName =
    this.theme?.editIconName ?? 'lnr lnr-pencil';

  get editIconButtonIconName(): string {
    return this._editIconButtonIconName;
  }

  private _enableInstitutionFilter: boolean;

  @Input({ transform: booleanAttribute })
  get enableInstitutionFilter(): boolean {
    return this._enableInstitutionFilter;
  }

  set enableInstitutionFilter(value: boolean) {
    this._enableInstitutionFilter = coerceBooleanProperty(value);
  }

  private _enableDepartmentFilter: boolean;

  @Input({ transform: booleanAttribute })
  get enableDepartmentFilter(): boolean {
    return this._enableDepartmentFilter;
  }

  set enableDepartmentFilter(value: boolean) {
    this._enableDepartmentFilter = coerceBooleanProperty(value);
  }

  private _enableUserFilter: boolean;

  @Input({ transform: booleanAttribute })
  get enableUserFilter(): boolean {
    return this._enableUserFilter;
  }

  set enableUserFilter(value: boolean) {
    this._enableUserFilter = coerceBooleanProperty(value);
  }

  private _hideFilter: boolean;

  @Input()
  get hideFilter(): boolean {
    return this._hideFilter;
  }

  set hideFilter(value: boolean) {
    this._hideFilter = coerceBooleanProperty(value);
  }

  get showEditActionButton(): boolean {
    return !!this._actionsService?.edit;
  }

  get showDeleteActionButton(): boolean {
    return !!this._actionsService?.delete && !this.hideDeleteButton;
  }

  get showActions(): boolean {
    return (
      !!this.preActions ||
      this.showEditActionButton ||
      this.showDeleteActionButton ||
      this.actionDefinitions?.length > 0
    );
  }

  get loading$() {
    return this._store.loading$;
  }

  get data$() {
    return this._store.vm$.pipe(map((r) => r.content));
  }

  get displayedColumns(): string[] {
    const select = this.enableSelection ? ['select'] : [];
    const actions = this.showActions ? ['actions'] : [];
    return [
      ...select,
      ...(this.columnDefinitions?.map((c) => c.columnName) ?? []),
      ...actions,
    ];
  }

  ngOnInit() {
    this.data$
      .pipe(
        tap(() => this._changeDetectorRef.detectChanges()),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    combineLatest([
      this._store.initialFilter$.pipe(filterNotNullOrUndefined()),
      this._store.initialSort$,
    ])
      .pipe(
        take(1),
        tap(([filter, sort]) => {
          this._store.setState({
            sort: sort?.active ?? this.sortStart,
            sortDirection: sort?.direction ?? this.sortStartDirection,
            content: [],
            currentPageIndex: 0,
            currentPageSize: this.initialPageSize,
            loading: true,
            totalElementCount: 0,
            queryParameters: isObservable(this._queryParams)
              ? null
              : this._queryParams,
            idEndpoint: this.idFilter ? '/' + this.idFilter : undefined,
            departments: filter.filterDepartments,
            users: filter.filterUsers,
            institutions: filter.filterInstitutions,
          });

          this._store.init();
          this._storeInitialized = true;
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  enableSelectionForRow(row: T): boolean {
    return this.enableSelection && this.canSelectRowFn?.(row) !== false;
  }

  onSortChange(sort: Sort) {
    this._store.sortUpdate({
      sort: sort.active,
      sortDirection: sort.direction,
    });
  }

  getItemName(row: T): string {
    return this.pluckItemNameFn?.(row) ?? 'Element';
  }

  getDeletePermissionsForRow$(row: T): null | Observable<TzPermissionRequest> {
    if (this.permissionConfig?.getDeletePermissionsForRow) {
      const request = this.permissionConfig.getDeletePermissionsForRow(row);
      return isObservable(request) ? request : of(request);
    }
    return this._true$;
  }

  getEditPermissionsForRow$(row: T): null | Observable<TzPermissionRequest> {
    if (this.permissionConfig?.getEditPermissionsForRow) {
      const request = this.permissionConfig.getEditPermissionsForRow(row);
      return isObservable(request) ? request : of(request);
    }
    return this._true$;
  }

  selectRows(...rows: T[]) {
    this._selection$.value.select(...rows);
  }

  clearSelection() {
    this._selection$.value.clear();
  }

  onClickRow(event: Event, item: T) {
    const { target } = event;
    if (
      target instanceof HTMLTableCellElement ||
      target instanceof HTMLTableRowElement
    ) {
      if (this.enableSelectionForRow(item)) {
        this._selection$.value.toggle(item);
      }
      this.rowClick.emit(item);
    }
  }

  async toggleAllRows() {
    const isAllSelected = await firstValueFrom(this.isAllSelected$);
    if (isAllSelected) {
      this._selection$.value.clear();
    } else {
      this.data$.pipe(take(1)).subscribe((data) => {
        const toSelect = data.filter((r) => this.enableSelectionForRow(r));
        this._selection$.value.select(...toSelect);
      });
    }
  }

  get showPointerCursorForRows(): boolean {
    return this.rowClick.observed;
  }

  onClickEditButton(item: T) {
    this._actionsService?.edit?.(item.id, () => this.reload());
  }

  async onClickDeleteButton(item: T) {
    if (this._actionsService?.delete) {
      this._actionsService.delete(item.id, () => this.reload());
      return;
    }
    const confirmed = await this._confirmationService
      .askUserForConfirmation({ warn: true })
      .toPromise();
    if (confirmed?.confirmed) {
      this._store.deleteItem(item);
    }
  }

  trackByRowId(index: number, row: T): string {
    return row.id;
  }

  reload() {
    this._store.reload();
  }

  executeBulkDelete() {
    this._bulkActionsService
      .openDialog<T>({
        execute: (item) =>
          this._store.dataSource.deleteById(item.id).pipe(map(() => void 0)),
        fetchItem: (item) => this._store.dataSource.getFreshById(item.id),
        isActionPermitted: (item) =>
          ensureObservable(this.getDeletePermissionsForRow$(item)).pipe(
            switchMap((r) => this._permissionCheckService.checkPermission$(r)),
          ),
        items: this._selection$.value.selected,
      })
      .pipe(
        filterTrue(),
        tap(() => this.reload()),
      )
      .subscribe();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }
}
