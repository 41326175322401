import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DataSourceMethodsPaginatedOptions,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { TextSnippet } from '@tremaze/text-snippets-types';
import { Observable } from 'rxjs';
import { TextSnippetDataSource } from './text-snippets-data-source';
import { Pagination } from '@tremaze/shared/models';

@Injectable()
export class TextSnippetsDataSourceImpl implements TextSnippetDataSource {
  private readonly _http = inject(HttpClient);

  getTextSnippet(id: string): Observable<TextSnippet> {
    return this._http.get<TextSnippet>(`/textSnippets/${id}`);
  }

  getTextSnippetsPageForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ) {
    return DefaultDataSourceMethods.getPaginated<TextSnippet>(
      this._http,
      `/institutions/${instId}/textSnippets`,
      (d) => d,
      options,
    );
  }

  getTextSnippetsPage(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<TextSnippet>> {
    return DefaultDataSourceMethods.getPaginated<TextSnippet>(
      this._http,
      `/textSnippets`,
      (d) => d,
      options,
    );
  }

  deleteTextSnippet(id: string): Observable<void> {
    return this._http.delete<void>(`/textSnippets/${id}`);
  }

  createTextSnippetForInstitution(
    instId: string,
    textSnippet: Required<Pick<TextSnippet, 'name' | 'text'>>,
  ): Observable<TextSnippet> {
    return this._http.post<TextSnippet>(
      `/institutions/${instId}/textSnippets`,
      textSnippet,
    );
  }

  updateTextSnippet(
    id: string,
    textSnippet: Required<Pick<TextSnippet, 'name' | 'text'>>,
  ): Observable<TextSnippet> {
    return this._http.put<TextSnippet>(`/textSnippets/${id}`, textSnippet);
  }
}

export const provideTextSnippetsDataSource = () => ({
  provide: TextSnippetDataSource,
  useClass: TextSnippetsDataSourceImpl,
});
