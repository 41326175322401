import { PaginatedSuggestionConfig, SuggestionConfig } from '../data-source';
import { UserTypeName } from '@tremaze/shared/feature/user/types';
import { remoteSuggestionsApiConfig } from './config';

export function configToQueryParamObject(
  action: unknown,
  config?: (PaginatedSuggestionConfig | SuggestionConfig) & {
    sort?: string;
  } & {
    userTypeIdentifiers?: UserTypeName[];
  },
  filterFields?: string[],
): Record<string, string> {
  if (!config) {
    return {};
  }
  return {
    suggestionAction: action as string,
    userId: config.userId ?? '',
    filterValue: config.filterValue ? `%${config.filterValue}%` : '',
    sort: config.sort ?? '',
    page: (config as PaginatedSuggestionConfig).page?.toString() ?? '0',
    size:
      (config as PaginatedSuggestionConfig).pageSize?.toString() ??
      remoteSuggestionsApiConfig.pageSize.toString(),
    filterFields: filterFields?.join(',') ?? '',
    userTypeIdentifier: config.userTypeIdentifiers?.join(',') ?? '',
    userTypeIdentifiers: config.userTypeIdentifiers?.join(',') ?? '',
  };
}
