import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SelectorComponent } from '@tremaze/shared/ui/selector';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgControl, Validators } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InstitutionREADDataSource } from '@tremaze/shared/feature/institution/data-access';
import { PrivilegeName } from '@tremaze/shared/permission/types';

@Component({
  selector: 'tremaze-institution-select',
  template: ` <tremaze-selector
    [value]="value"
    [dataSource]="institutionDataSource"
    [multiple]="multiple"
    [required]="required"
    [withAddOption]="withAddOption"
    (selectionChange)="selectionChange.emit($event)"
  ></tremaze-selector>`,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    { provide: MatFormFieldControl, useExisting: InstitutionSelectComponent },
  ],
})
export class InstitutionSelectComponent
  implements
    OnInit,
    OnDestroy,
    DoCheck,
    MatFormFieldControl<Institution | Institution[]>
{
  static nextId = 0;
  error = false;
  errorState = false;
  controlType?: string;
  autofilled?: boolean;
  stateChanges = new Subject<void>();
  defaultPlaceholder: string;
  @HostBinding()
  id = `institution-selector-${InstitutionSelectComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Output() clickedAddOption = new EventEmitter();

  @Output() selectionChange = new EventEmitter<Institution | Institution[]>();

  @ViewChild(SelectorComponent, { static: true })
  private _input: SelectorComponent<Institution>;
  private _reloadSubject = new Subject();
  private _destroyed$ = new Subject();

  constructor(
    private dataSource: InstitutionREADDataSource,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input() forPrivilege?: PrivilegeName[];

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get empty() {
    if (this.value instanceof Array) {
      return !this.value.length;
    }
    return this.value === null || this.value === undefined;
  }

  private _withAddOption = false;

  @Input()
  get withAddOption(): boolean {
    return this._withAddOption;
  }

  set withAddOption(value: boolean) {
    this._withAddOption = coerceBooleanProperty(value);
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next(null);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return (
      this._required ||
      this.ngControl?.control?.hasValidator(Validators.required)
    );
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _multiple = false;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _value: Institution | Institution[] | null;

  get value() {
    return this._value;
  }

  @Input()
  set value(v: Institution | Institution[] | null) {
    if (v === undefined) {
      v = null;
    }
    this._value = v;
    this.stateChanges.next(null);
    this.onChangeCallback(this.value);
  }

  private _focused = false;

  get focused(): boolean {
    return this._focused && !this.disabled;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  institutionDataSource = () =>
    this.dataSource
      .getPaginated(
        { filter: { sort: 'name', sortDirection: 'asc' } },
        this.forPrivilege,
      )
      .pipe(map((r) => r.content));

  reload() {
    this._reloadSubject.next(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  onContainerClick(event: MouseEvent): void {
    this.focused = true;
    this._input.focused = true;
    this._input.onContainerClick(event);
  }

  ngOnInit() {
    this.placeholder = this.defaultPlaceholder || 'Bitte auswählen';
    if (this._input instanceof SelectorComponent) {
      this._input.selectionChange
        .pipe(takeUntil(this._destroyed$))
        .subscribe((r: Institution | Institution[]) => {
          this.value = r;
        });
      this.fm
        .monitor(this._input.elementRef?.nativeElement, true)
        .pipe(takeUntil(this._destroyed$))
        .subscribe((origin) => {
          this._focused = !!origin;
          this.stateChanges.next(null);
        });
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._reloadSubject.complete();
    this._destroyed$.next(null);
    this._destroyed$.complete();
    this.fm.stopMonitoring(this._input?.elementRef?.nativeElement);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
    }
  }

  registerOnChange(fn): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj): void {
    if (this._value !== obj) {
      this.value = obj;
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouchedCallback: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChangeCallback: (_) => void = () => {};
}
