import { Observable } from 'rxjs';
import { Department } from '@tremaze/shared/feature/department/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Division } from '@tremaze/shared/feature/division/types';
import { User, UserTypeName } from '@tremaze/shared/feature/user/types';
import { Pagination } from '@tremaze/shared/models';
import { TremazeLocation } from '@tremaze/location-types';
import { Case } from '@tremaze/case-types';

export type SuggestionConfig =
  | { userId?: string; filterValue?: string }
  | undefined;

export type PaginatedSuggestionConfig = SuggestionConfig & {
  page?: number;
  pageSize?: number;
};

export type AssignmentAction = 'write' | 'delete';

export type AssignmentTarget =
  | 'event'
  | 'information'
  | 'division'
  | 'institution'
  | 'family'
  | 'approval'
  | 'location'
  | 'case';

/**
 * @description This data source can be used to get specific entities from
 * the api that can be used to assign to other entities. This currently supports
 * Users, Departments, and Institutions.
 * A maximum of 50 entities will be returned.
 */
export abstract class SuggestionsDataSource {
  abstract getDepartments(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    instIds?: string[],
  ): Observable<Department[]>;

  abstract getInstitutions(
    target: AssignmentTarget,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Institution[]>;

  abstract getDivisions(
    target: Omit<AssignmentTarget, 'division'>,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Division[]>;

  abstract getUsers(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    userTypes?: UserTypeName[],
    instIds?: string[],
  ): Observable<User[]>;

  abstract getCases(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    instIds?: string[],
    userIds?: string[],
  ): Observable<Pagination<Case>>;

  abstract getEventResourceViewUserSuggestions(
    config?: PaginatedSuggestionConfig,
  ): Observable<Pagination<User>>;

  abstract getManagedAccountsSuggestions(
    config?: SuggestionConfig,
  ): Observable<User[]>;

  abstract getLocations(
    config?: SuggestionConfig,
  ): Observable<TremazeLocation[]>;
}
