import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { UserTypeNameSelectionType } from '@tremaze/shared/feature/user/types';

@staticImplements<Deserializable<ExternalResource>>()
export class ExternalResource {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly url: string,
    readonly userTypes: UserTypeNameSelectionType,
    readonly image?: FileStorage,
    readonly openInInAppWebView?: boolean,
  ) {}

  static deserialize(data: any): ExternalResource {
    return new ExternalResource(
      data.id,
      data.title,
      data.url,
      data.userTypes,
      FileStorage.deserialize(data.image),
      data.openInInAppWebView,
    );
  }
}
