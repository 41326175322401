import { Observable } from 'rxjs';
import { FullTenantConfig, TremazeModuleName } from './types';

export abstract class TenantConfigService {
  abstract get config$(): Observable<FullTenantConfig>;

  abstract get config(): FullTenantConfig | null;

  abstract get hasConfig$(): Observable<boolean>;

  abstract get fakeAccountsEnabled$(): Observable<boolean>;

  abstract get fakeAccountsAreDefault$(): Observable<boolean>;

  abstract get customFormEnabled$(): Observable<boolean>;

  abstract get workflowEnabled$(): Observable<boolean>;

  abstract get goalsEnabled$(): Observable<boolean>;

  abstract get specializationEnabled$(): Observable<boolean>;

  abstract get additionalInfoEnabled$(): Observable<boolean>;

  abstract get additionalInfo2Enabled$(): Observable<boolean>;

  abstract get eventScheduleEnabled$(): Observable<boolean>;

  abstract get postboxEnabled$(): Observable<boolean>;

  abstract get familyEnabled$(): Observable<boolean>;

  abstract get contactPointEnabled$(): Observable<boolean>;

  abstract get chatEnabled$(): Observable<boolean>;

  abstract get dashboardEnabled$(): Observable<boolean>;

  abstract get eventPresetsEnabled$(): Observable<boolean>;

  abstract get approvalEnabled$(): Observable<boolean>;

  abstract get caseManagementEnabled$(): Observable<boolean>;

  abstract reload(): void;

  abstract isModuleEnabled(moduleName: TremazeModuleName): Observable<boolean>;
}
