import {
  AfterViewChecked,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BehaviorSubject,
  combineLatest,
  delay,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  startWith,
  take,
} from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { negateBool } from '@tremaze/shared/util/rxjs';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'tremaze-lazy-load-list, [tremaze-lazy-load-list]',
  standalone: true,
  imports: [CommonModule, MatProgressSpinner],
  template: `
    @if (hasLoadedInitialItems$ | async) {
      <div
        class="scroll-container"
        #scrollContainer
        [style.gap]="gap"
        [style.padding]="padding"
        [@listAnimation]="loadedItemsCount$ | async"
      >
        @for (item of delayedLoadedItems$ | async; track item.id) {
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
          ></ng-container>
        }
        @if (showLoader$ | async) {
          <div class="center-children">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
        }
      </div>
    }
    @if (showNoItemsMessage$ | async) {
      <div class="center-children" style="height: 100%">
        Keine Einträge vorhanden
      </div>
    }
    @if (isInitialLoad$ | async) {
      <div class="center-children">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
    }
  `,
  styles: `
    :host {
      display: flex;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .scroll-container {
      overflow-y: auto;
      flex: 1;
      display: flex;
      width: 100%;
      flex-direction: column;
      box-sizing: border-box;

      > *:last-child {
        margin-bottom: 200px;
      }
    }

    .center-children {
      padding: 40px;
      position: absolute;
      inset: 0;
    }
  `,
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(
          ':enter',
          [
            style({ opacity: 0.5, transform: 'translateY(10px)' }),
            stagger(10, [
              animate(
                '0.2s ease-in',
                style({ opacity: 1, transform: 'translateY(0)' }),
              ),
            ]),
          ],
          { optional: true },
        ),
      ]),
    ]),
  ],
})
export class LazyLoadListComponent<T>
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() step = 20; // Number of items to load each time
  @Input({ required: true }) loadEntriesPage: (
    page: number,
    size: number,
  ) => Observable<Pagination<T>>;

  @Input() gap?: string;
  @Input() padding?: string;

  @ViewChild('scrollContainer')
  private scrollContainer: ElementRef<HTMLDivElement>;
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

  readonly loadedItems$ = new BehaviorSubject<T[]>([]);

  readonly delayedLoadedItems$ = this.loadedItems$.pipe(
    delay(1),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly currentPage$ = new BehaviorSubject<number>(0);
  readonly totalItems$ = new BehaviorSubject<number>(-1);

  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  readonly loadedItemsCount$ = this.delayedLoadedItems$.pipe(
    map((items) => items.length),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly isInitialLoad$ = this.totalItems$.pipe(
    map((total) => total === -1),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly hasLoadedInitialItems$ = this.isInitialLoad$.pipe(
    negateBool(),
    startWith(false),
    distinctUntilChanged(),
    take(2),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly hasMoreItems$ = this.totalItems$.pipe(
    map((total) => this.loadedItems$.value.length < total),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly showLoader$ = combineLatest([
    this.isLoading$,
    this.hasMoreItems$,
    this.isInitialLoad$,
  ]).pipe(
    map(
      ([isLoading, hasMoreItems, isInitialLoad]) =>
        isInitialLoad || (isLoading && hasMoreItems),
    ),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly showNoItemsMessage$ = combineLatest([
    this.loadedItems$,
    this.isLoading$,
    this.isInitialLoad$,
  ]).pipe(
    map(
      ([loadedItems, isLoading, isInitialLoad]) =>
        loadedItems.length === 0 && !isLoading && !isInitialLoad,
    ),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  get hasMoreItems(): boolean {
    return this.loadedItems$.value.length < this.totalItems$.value;
  }

  reload(): void {
    this.loadedItems$.next([]);
    this.currentPage$.next(0);
    this.totalItems$.next(-1);
    this.fetchPage();
  }

  private _hasAttachedScrollListener = false;

  ngOnInit(): void {
    this.fetchPage();
  }

  ngAfterViewChecked(): void {
    if (this._hasAttachedScrollListener || !this.scrollContainer) {
      return;
    }
    this.scrollContainer.nativeElement.addEventListener('scroll', () =>
      this.onScroll(),
    );
    this._hasAttachedScrollListener = true;
  }

  onScroll(): void {
    const container = this.scrollContainer.nativeElement;
    const { scrollTop, scrollHeight, clientHeight } = container;

    // Check if the user has scrolled to the bottom - 150px
    if (scrollTop + clientHeight >= scrollHeight - 150) {
      this.fetchPage();
    }
  }

  private fetchPage(): void {
    if (
      this.isLoading$.value ||
      (this.totalItems$.value >= 0 && !this.hasMoreItems)
    ) {
      return; // Stop loading if all items have been loaded
    }

    this.isLoading$.next(true);
    this.loadEntriesPage(this.currentPage$.value, this.step).subscribe({
      next: (pagination: Pagination<T>) => {
        this.loadedItems$.next([
          ...this.loadedItems$.value,
          ...pagination.content,
        ]);
        this.totalItems$.next(pagination.totalElements);
        this.currentPage$.next(this.currentPage$.value + 1);
      },
      error: (err) => console.error('Error fetching page: ', err),
      complete: () => this.isLoading$.next(false),
    });
  }

  ngOnDestroy(): void {
    if (this.scrollContainer?.nativeElement) {
      this.scrollContainer.nativeElement.removeEventListener(
        'scroll',
        this.onScroll,
      );
    }
  }
}
