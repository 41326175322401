import { SharedNotificationModule } from '@tremaze/shared/notification';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsAutocompleteComponent } from './chips-autocomplete.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedFileStorageUiCircleAvatarModule } from '@tremaze/shared/feature/file-storage/ui/circle-avatar';
import { IconComponent } from '@tremaze/shared/ui/icon';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    SharedNotificationModule,
    MatInputModule,
    SharedFileStorageUiCircleAvatarModule,
    IconComponent,
  ],
  declarations: [ChipsAutocompleteComponent],
  exports: [ChipsAutocompleteComponent],
})
export class SharedComponentsChipsAutocompleteModule {}
