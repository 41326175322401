import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { TextSnippet } from '@tremaze/text-snippets-types';
import { TextSnippetSelectDialogComponent } from './text-snippet-select.dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TextSnippetSelectService {
  private readonly _dialog = inject(MatDialog);

  selectSnippet(): Observable<TextSnippet | undefined> {
    return this._dialog
      .open(TextSnippetSelectDialogComponent)
      .afterClosed()
      .pipe(map((s) => s ?? undefined));
  }
}
