import {
  CustomForm,
  CustomFormSubmission,
  CustomFormVersion,
  CustomTextBoxFormField,
} from '@tremaze/shared/feature/custom-forms/types';
import { Meta } from '@tremaze/shared/models';
import { PageGenerator } from '../helpers';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { UserFixtures } from '../user';

class CustomFormFixturePageGenerator extends PageGenerator<CustomForm> {
  override generateByIndex(index: number): CustomForm {
    return CustomFormFixtures.generateCustomForm(index.toString());
  }
}

export abstract class CustomFormFixtures {
  static generateCustomFormField = (id: string) =>
    new CustomTextBoxFormField(
      id,
      new Meta(),
      `Field ${id}`,
      `Field ${id}`,
      true,
      0,
      `${id}`,
    );

  static generateCustomFormVersion = (id: string) => {
    const fields = Array.from({ length: 5 }, (_, i) =>
      CustomFormFixtures.generateCustomFormField(i.toString()),
    );

    return new CustomFormVersion(fields, id, {
      id,
      name: `Form ${id}`,
      institutions: [],
    });
  };

  static generateCustomForm = (id: string): CustomForm =>
    new CustomForm(
      id,
      new Meta(),
      `Form ${id}`,
      true,
      CustomFormFixtures.generateCustomFormVersion(id),
    );

  static generateCustomFormSubmission = (id: string) =>
    new CustomFormSubmission(
      id,
      new Meta(),
      CustomFormFixtures.generateCustomFormVersion(id),
      UserFixtures.generateUser(1),
      false,
    );

  private static _customFormStubPageGenerator =
    new CustomFormFixturePageGenerator();

  static generateCustomFormPage = (
    options: DataSourceMethodsPaginatedOptions,
  ) => CustomFormFixtures._customFormStubPageGenerator.generatePage(options);
}
