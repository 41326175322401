export type ColoredBadgeColor =
  | 'blue'
  | 'green'
  | 'orange'
  | 'cyan'
  | 'purple'
  | 'yellow'
  | 'wine'
  | 'red'
  | 'gray';

export type ColoredBadgeColorValue =  {
  background: string;
  foreground: string;
};

export const  coloredBadgeColorMap: Record<
  ColoredBadgeColor,
  ColoredBadgeColorValue
> = {
  blue: { background: '#E3F2FD', foreground: '#0D47A1' },
  green: { background: '#E8F5E9', foreground: '#1B5E20' },
  orange: { background: '#FFF3E0', foreground: '#E65100' },
  cyan: { background: '#E0F7FA', foreground: '#006064' },
  purple: { background: '#EDE7F6', foreground: '#4A148C' },
  yellow: { background: '#FFFDE7', foreground: '#F57F17' },
  wine: { background: '#FCE4EC', foreground: '#AD1457' },
  red: { background: '#FFEBEE', foreground: '#C62828' },
  gray: { background: '#F5F5F5', foreground: '#424242' },
};
