import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  MatChipInput,
  MatChipInputEvent,
  MatChipListbox,
} from '@angular/material/chips';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { NotificationService } from '@tremaze/shared/notification';
import { Failure } from '@tremaze/shared/util-error';
import {
  distinctUntilChanged,
  filter,
  fromEvent,
  merge,
  Observable,
  Subject,
} from 'rxjs';
import { debounceTime, delay, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'tremaze-chips-autocomplete',
  template: `
    <mat-chip-grid #chipList>
      <mat-chip-row
        color="accent"
        *ngFor="let item of value"
        [disabled]="disabled"
        [removable]="!disabled"
        selected
        (removed)="remove(item)"
        (click)="clickedValueChip.emit(item)"
      >
        <tremaze-circle-avatar
          *ngIf="getOptionAvatar"
          [file]="getOptionAvatar(item)"
          [radius]="9"
          matChipAvatar
          [canShowPreviewOverlay]="false"
          [fallbackInitials]="getOptionInitials?.(item)"
        ></tremaze-circle-avatar>
        <span class="chip-title">
          {{ getOptionLabel(item) }}
        </span>
        <span matChipRemove>
          <tremaze-icon icon="lnr-cross"></tremaze-icon>
        </span>
      </mat-chip-row>
      <input
        #chipInput
        [formControl]="inputControl"
        matInput
        [placeholder]="placeholder"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [maxLength]="maxLength || 524288"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
      <ng-template #placeholderEl
        ><span style="height: 26px"></span
      ></ng-template>
    </mat-chip-grid>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <ng-container *ngIf="data$ | async; let data">
        <ng-container *ngIf="data.length; else noResults">
          <ng-container *ngFor="let item of data">
            <mat-option
              *ngIf="!isItemSelected(item)"
              [value]="item"
              [disabled]="disabled"
            >
              <div class="d-flex center-children-vertically">
                <tremaze-circle-avatar
                  *ngIf="getOptionAvatar"
                  style="margin-right: 8px;"
                  [radius]="15"
                  [file]="getOptionAvatar(item)"
                  [canShowPreviewOverlay]="false"
                  [fallbackInitials]="getOptionInitials?.(item)"
                ></tremaze-circle-avatar>
                <div>
                  <div>
                    <b class="chip-title">{{ getOptionLabel(item) }}</b>
                  </div>
                  <div *ngIf="getOptionSubtitle" class="sub-label">
                    <small>{{ getOptionSubtitle(item) }}</small>
                  </div>
                </div>
              </div>
            </mat-option>
          </ng-container>
        </ng-container>

        <ng-template #noResults>
          <mat-option disabled>
            <div class="d-flex center-children-vertically">
              <div>
                <div>
                  <b>Keine Ergebnisse</b>
                </div>
              </div>
            </div>
          </mat-option>
        </ng-template>
      </ng-container>
    </mat-autocomplete>
  `,
  providers: [
    { provide: MatFormFieldControl, useExisting: ChipsAutocompleteComponent },
  ],
  styles: `
    .chip-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
})
export class ChipsAutocompleteComponent<T>
  implements
    OnInit,
    OnDestroy,
    DoCheck,
    AfterViewInit,
    MatFormFieldControl<Array<T>>,
    ControlValueAccessor
{
  static nextId = 0;
  data$: Observable<T[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  error = false;
  errorState = false;
  controlType?: string;
  autofilled?: boolean;
  stateChanges = new Subject<void>();
  defaultPlaceholder: string;
  @HostBinding() id = `generic-selector-${ChipsAutocompleteComponent.nextId++}`;
  @HostBinding('style.display') display = 'contents';
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Input() dataSource: (filterVal: string) => Observable<Failure | T[]>;
  @Input() createItem: (itemName: string) => Observable<Failure | T>;
  @Input() maxLength: number;
  inputControl = new UntypedFormControl({
    value: null,
    disabled: this.disabled,
  });
  @Output() selectionChange = new EventEmitter<T | T[]>();
  @Output() clickedValueChip = new EventEmitter<T>();
  @ViewChild('chipInput') private _input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  private matAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('chipInput', { read: MatChipInput })
  private autocompleteInput: MatChipInput;
  @ViewChild(MatChipListbox) private chipList: MatChipListbox;
  @ViewChild(MatAutocomplete) private autocomplete: MatAutocomplete;
  private reload = new Subject();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private notificationService: NotificationService,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next(null);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return (
      this._required ||
      this.ngControl?.control?.hasValidator(Validators.required)
    );
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (!value) {
      this.inputControl?.enable();
    } else {
      this.inputControl?.disable();
    }
    this.stateChanges.next(null);
  }

  private _value: T[] | null;

  get value() {
    if (this._value && !Array.isArray(this._value)) {
      return [this._value];
    }
    return this._value || [];
  }

  @Input()
  set value(v: T[] | null) {
    if (v && !Array.isArray(v)) {
      v = [v];
    }
    this._value = v || [];
  }

  private _focused = false;

  get focused(): boolean {
    return this._focused && !this.disabled;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty || this.inputControl?.value?.length;
  }

  get empty() {
    if (this.value instanceof Array) {
      return !this.value.length;
    }
    return !this.value;
  }

  ngAfterViewInit() {
    if (this._input) {
      fromEvent(this._input.nativeElement, 'focus')
        .pipe(
          delay(1),
          filter(() => !this.disabled),
        )
        .subscribe(() => {
          this.matAutocompleteTrigger.openPanel();
        });
    }
  }

  isItemSelected(item: T) {
    return Array.isArray(this.value)
      ? this.value?.some((i) => this.comparator(i, item))
      : false;
  }

  async add(v: MatChipInputEvent) {
    const r$ = this.createItem;
    if (r$) {
      const newItem = await r$(v.value).toPromise();
      if (newItem instanceof Failure) {
        this.notificationService?.showDefaultErrorNotification();
      } else {
        this.value = [...(this.value || []), newItem];
        this._input.nativeElement.value = null;
        this.inputControl.setValue(null);
      }
    }
  }

  remove(v: T) {
    this.value = this.value?.filter((c) => !this.comparator(c, v));
    this.onChange();
  }

  selected(event: MatAutocompleteSelectedEvent) {
    if (!this.isItemSelected(event.option.value)) {
      this.value = [...this.value, event.option.value];
      this._input.nativeElement.value = null;
      this.inputControl.setValue(null);
      this.onChange();
    }
  }

  @Input() getOptionLabel: (val: T) => string = (val) => (val as any).name;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() getOptionAvatar?: (val: T) => FileStorage;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() getOptionInitials?: (val: T) => string;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() getOptionSubtitle?: (val: T) => string;

  @Input() comparator: (val1: T, val2: T) => boolean = (v1, v2) =>
    (v1 as any)?.id === (v2 as any)?.id;

  onContainerClick(event: MouseEvent): void {
    this._input?.nativeElement.focus();
    this._focused = true;
    event.preventDefault();
  }

  @HostListener('focusout')
  onFocusRemoved() {
    this._focused = false;
    this._input.nativeElement.blur();
    this.stateChanges.next(null);
  }

  @HostListener('focusin')
  onFocus() {
    this._focused = true;
    this.stateChanges.next(null);
  }

  public triggerReload() {
    this.reload.next(null);
  }

  ngOnInit() {
    if (!this.dataSource) {
      throw new Error(
        'ChipsAutocomplete must be provided with a dataSource Input',
      );
    }
    this.placeholder = this.defaultPlaceholder || 'Bitte auswählen';
    this.data$ = merge(
      this.reload,
      this.inputControl.valueChanges.pipe(
        debounceTime(300),
        startWith(''),
        distinctUntilChanged(),
      ),
    ).pipe(
      switchMap((val) => {
        return this.dataSource(val).pipe(
          map((r) => {
            return r instanceof Failure ? [] : r;
          }),
        );
      }),
    );
    if (this.ngControl) {
      this.disabled = this.ngControl.disabled;
      this.ngControl.statusChanges?.subscribe(() => {
        const disabled = this.ngControl.disabled;
        if (disabled !== null) {
          this.disabled = disabled;
        }
      });
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
    }
  }

  onChange() {
    this.selectionChange.emit(this.value);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.value);
    }
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
    this.stateChanges.next(null);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  writeValue(obj: any): void {
    if (this.value !== obj) {
      this.value = obj;
    }
  }

  private onTouchedCallback: () => void = () => {};

  private onChangeCallback: (_: any) => void = () => {};
}
