import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { FilePreviewOverlayService } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';

@Component({
  selector: 'tremaze-circle-avatar',
  template: `
    <div
      *ngIf="file; else fallback"
      [style.borderRadius]="radius + 'px'"
      [style.cursor]="canShowPreviewOverlay ? 'pointer' : 'normal'"
      [style.height]="size"
      [style.width]="size"
      [style.min-width]="size"
      [style.max-width]="size"
      [style.min-height]="size"
      [style.max-height]="size"
      [style.border-style]="'solid'"
      [style.borderColor]="borderColor"
      [style.border-width]="borderWidth + 'px'"
      class="circle-avatar"
    >
      <img
        *ngIf="getUrl$() | async; let url"
        (click)="onClick($event)"
        [alt]="file.fileViewname"
        [src]="url"
      />
    </div>
    <ng-template #fallback>
      <tremaze-initials-circle *ngIf="fallbackInitials" [radius]="radius">{{
        fallbackInitials
      }}</tremaze-initials-circle>
    </ng-template>
    <ng-content select="[tremazeCircleAvatarBadge]"></ng-content>
  `,
  styleUrls: ['./circle-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CircleAvatarComponent {
  private _radius = 20;

  get borderWidth() {
    return this.borderColor === 'transparent' ? 0 : 1;
  }

  get radius(): number {
    return this._radius - this.borderWidth;
  }

  @Input() fallbackInitials?: string;

  @Input() set radius(value: number) {
    this._radius = value;
  }

  @Input() file?: FileStorage | null | undefined;
  @Input() canShowPreviewOverlay = true;
  @Input() borderColor = 'transparent';

  @HostBinding('class') get hostClass(): string {
    return 'circle-avatar';
  }

  constructor(
    private _configService: AppConfigService,
    private fileStorageService: FileStorageService,
    @Optional() private _filePreviewOverlayService: FilePreviewOverlayService,
  ) {}

  private _stopPropagation: boolean;

  @Input()
  get stopPropagation(): boolean {
    return this._stopPropagation;
  }

  set stopPropagation(val) {
    this._stopPropagation = coerceBooleanProperty(val);
  }

  @HostBinding('style.width')
  @HostBinding('style.height')
  @HostBinding('style.minWidth')
  @HostBinding('style.maxWidth')
  @HostBinding('style.minHeight')
  @HostBinding('style.maxHeight')
  get size() {
    return `${this.radius * 2}px`;
  }

  getUrl$(fullWidth?: boolean) {
    return this.fileStorageService.getFileDownloadURL(
      this.file,
      fullWidth ? null : this.radius * 2,
    );
  }

  async onClick(event: Event) {
    if (this.canShowPreviewOverlay && this.file) {
      event?.stopPropagation?.();
      event?.stopImmediatePropagation?.();
      if (
        this._filePreviewOverlayService instanceof FilePreviewOverlayService
      ) {
        this._filePreviewOverlayService.open({
          data: {
            url: await this.getUrl$(true).toPromise(),
            name: this.file.fileViewname,
            type: this.file.type,
          },
        });
      } else {
        throw new Error('Missing provider of FilePreviewOverlayService');
      }
    }
  }
}
