import { User, UserType } from '@tremaze/shared/feature/user/types';
import { ContactInfo, Meta } from '@tremaze/shared/models';
import { PageGenerator } from '../helpers';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';

const germanFirstNames: string[] = [
  'Anna',
  'Ben',
  'Charlotte',
  'David',
  'Emma',
  'Felix',
  'Greta',
  'Hannah',
  'Isabel',
  'Jonas',
  'Klara',
  'Lars',
  'Marie',
  'Noah',
  'Olivia',
  'Paul',
  'Quentin',
  'Rosa',
  'Sebastian',
  'Theresa',
  'Uwe',
  'Valentina',
  'Wolfgang',
  'Xenia',
  'Yannick',
  'Zoe',
  'Adrian',
  'Bianca',
  'Clara',
  'Dominik',
  'Elisa',
  'Fabian',
  'Gustav',
  'Helena',
  'Ingo',
  'Johanna',
  'Kai',
  'Lea',
  'Matthias',
  'Nina',
  'Oskar',
  'Petra',
  'Quirin',
  'Ralf',
  'Stefanie',
  'Tobias',
  'Ulrike',
  'Victor',
  'Werner',
  'Xaver',
  'Yvonne',
  'Anton',
  'Britta',
  'Clemens',
  'Doris',
  'Erik',
  'Franziska',
  'Gisela',
  'Hermann',
  'Ilse',
  'Jakob',
  'Karoline',
  'Lukas',
  'Marlene',
  'Nico',
  'Otto',
  'Paula',
  'Richard',
  'Simone',
  'Tom',
  'Ursula',
  'Vanessa',
  'Wiebke',
  'Xander',
  'Yara',
  'Zacharias',
  'Ansgar',
  'Bettina',
  'Christian',
  'Dagmar',
  'Erika',
  'Friedrich',
  'Gerd',
  'Heike',
  'Irene',
  'Jürgen',
  'Katrin',
  'Lorenz',
  'Manuel',
  'Nils',
  'Oliver',
  'Philipp',
  'Renate',
  'Sven',
  'Thomas',
  'Ulrich',
  'Verena',
  'Wilhelm',
  'Xenia',
];

const germanLastNames: string[] = [
  'Müller',
  'Schmidt',
  'Schneider',
  'Fischer',
  'Weber',
  'Meyer',
  'Wagner',
  'Becker',
  'Hoffmann',
  'Schäfer',
  'Koch',
  'Bauer',
  'Richter',
  'Klein',
  'Wolf',
  'Schröder',
  'Neumann',
  'Schwarz',
  'Zimmermann',
  'Braun',
  'Krüger',
  'Hofmann',
  'Hartmann',
  'Lange',
  'Schmitt',
  'Werner',
  'Schmitz',
  'Krause',
  'Meier',
  'Lehmann',
  'Schmid',
  'Schulz',
  'Maier',
  'Köhler',
  'Herrmann',
  'König',
  'Walter',
  'Mayer',
  'Huber',
  'Kaiser',
  'Fuchs',
  'Peters',
  'Lang',
  'Scholz',
  'Möller',
  'Weiß',
  'Jung',
  'Hahn',
  'Schubert',
  'Vogel',
  'Friedrich',
  'Keller',
  'Günther',
  'Frank',
  'Berger',
  'Winkler',
  'Roth',
  'Beck',
  'Lorenz',
  'Baumann',
  'Franke',
  'Albrecht',
  'Schuster',
  'Simon',
  'Ludwig',
  'Böhm',
  'Winter',
  'Kraus',
  'Martin',
  'Schumacher',
  'Krämer',
  'Vogt',
  'Stein',
  'Jäger',
  'Otto',
  'Sommer',
  'Groß',
  'Seidel',
  'Heinrich',
  'Brandt',
  'Haas',
  'Schreiber',
  'Graf',
  'Dietrich',
  'Ziegler',
  'Kuhn',
  'Kühn',
  'Pohl',
  'Engel',
  'Horn',
  'Busch',
  'Bergmann',
  'Thomas',
  'Voigt',
  'Sauer',
  'Arnold',
  'Wolff',
  'Kaufmann',
  'Michel',
  'Marx',
];

function generateFirstName(index: number) {
  return germanFirstNames[index % germanFirstNames.length];
}

function generateLastName(index: number) {
  return germanLastNames[index % germanLastNames.length];
}

class UserFixturePageGenerator extends PageGenerator<User> {
  override generateByIndex(index: number): User {
    return UserFixtures.generateUser(index);
  }
}

export abstract class UserFixtures {
  private static _userStubPageGenerator = new UserFixturePageGenerator();

  static generateUserPage = (options: DataSourceMethodsPaginatedOptions) =>
    UserFixtures._userStubPageGenerator.generatePage(options);

  static generateUser = (
    id: number,
    userType = UserFixtures.employeeUserType,
  ): User => {
    return new User(
      `user${id}`,
      new Meta(),
      `user${id}`,
      generateFirstName(id),
      generateLastName(id),
      undefined,
      undefined,
      new ContactInfo(`user${id}@mail.com`),
      undefined,
      true,
      true,
      true,
      true,
      undefined,
      undefined,
      [],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      [userType],
    );
  };

  static readonly clientUserType: UserType = {
    name: 'USER',
    id: 'user',
    viewName: 'Client',
  };

  static readonly employeeUserType: UserType = {
    name: 'EMPLOYEE',
    id: 'employee',
    viewName: 'Employee',
  };

  static readonly client1 = UserFixtures.generateUser(
    1,
    UserFixtures.clientUserType,
  );
  static readonly client2 = UserFixtures.generateUser(
    2,
    UserFixtures.clientUserType,
  );
  static readonly client3 = UserFixtures.generateUser(
    3,
    UserFixtures.clientUserType,
  );
  static readonly client4 = UserFixtures.generateUser(
    4,
    UserFixtures.clientUserType,
  );
  static readonly client5 = UserFixtures.generateUser(
    5,
    UserFixtures.clientUserType,
  );

  static readonly employee1 = UserFixtures.generateUser(
    1,
    UserFixtures.employeeUserType,
  );
  static readonly employee2 = UserFixtures.generateUser(
    2,
    UserFixtures.employeeUserType,
  );
  static readonly employee3 = UserFixtures.generateUser(
    3,
    UserFixtures.employeeUserType,
  );
  static readonly employee4 = UserFixtures.generateUser(
    4,
    UserFixtures.employeeUserType,
  );
  static readonly employee5 = UserFixtures.generateUser(
    5,
    UserFixtures.employeeUserType,
  );

  static readonly allClients = [
    UserFixtures.client1,
    UserFixtures.client2,
    UserFixtures.client3,
    UserFixtures.client4,
    UserFixtures.client5,
  ];

  static readonly allEmployees = [
    UserFixtures.employee1,
    UserFixtures.employee2,
    UserFixtures.employee3,
    UserFixtures.employee4,
    UserFixtures.employee5,
  ];

  static readonly allUsers = [
    ...UserFixtures.allClients,
    ...UserFixtures.allEmployees,
  ];
}
