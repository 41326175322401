import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { User } from '@tremaze/shared/feature/user/types';
import { CaseStatus, deserializeCaseStatus } from './case-status';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { CaseStatisticIdentifier } from './case-statistic-identifier';
import { Approval } from '@tremaze/shared/feature/approval/types';
import { Department } from '@tremaze/shared/feature/department/types';

@staticImplements<Deserializable<Case>>()
export class Case {
  constructor(
    readonly id: string,
    readonly caseNumber: string,
    readonly status?: CaseStatus,
    readonly client?: User,
    readonly employees: User[] = [],
    readonly institution?: Institution,
    readonly additionalInfo?: string,
    readonly startDate?: TremazeDate,
    readonly endDate?: TremazeDate,
    readonly registrationDate?: TremazeDate,
    readonly statisticsIdentifier?: CaseStatisticIdentifier,
    readonly approvals: Approval[] = [],
    readonly departments: Department[] = [],
  ) {}

  static deserialize(input: any): Case {
    return new Case(
      input.id,
      input.caseNumber,
      input.status ? deserializeCaseStatus(input.status) : undefined,
      input.client ? User.deserialize(input.client)! : undefined,
      input.employees?.map((employee: any) => User.deserialize(employee)),
      input.institution
        ? Institution.deserialize(input.institution)
        : undefined,
      input.additionalInfo,
      TremazeDate.deserialize(input.startDate),
      TremazeDate.deserialize(input.endDate),
      TremazeDate.deserialize(input.registrationDate),
      input.statisticIdentifier,
      (input.approvals ?? []).map((approval: any) =>
        Approval.deserialize(approval),
      ),
      (input.departments ?? []).map((department: any) =>
        Department.deserialize(department),
      ),
    );
  }
}
