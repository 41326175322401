import {
  AssignmentAction,
  AssignmentTarget,
  SuggestionConfig,
  SuggestionsDataSource,
} from '../data-source';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { map, Observable } from 'rxjs';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { DefaultDataSourceMethods } from '@tremaze/shared/util-http';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { remoteSuggestionsApiConfig } from './config';

@Injectable({
  providedIn: 'root',
})
export class RemoteInstitutionsSuggestionsDataSource
  implements Pick<SuggestionsDataSource, 'getInstitutions'>
{
  private readonly _http = inject(HttpClient);

  private _getInstitutionsByPrivilege(
    privileges: PrivilegeName[],
    filterValue?: string,
  ): Observable<Institution[]> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'cc/entityPermissions/institutions',
      Institution.deserialize,
      {
        q: {
          perms: privileges,
        },
        filter: {
          pageSize: remoteSuggestionsApiConfig.pageSize,
          filterValue,
          filterFields: ['NAME'],
          sort: 'name',
          sortDirection: 'asc',
        },
      },
    ).pipe(map((response) => response.content));
  }

  private _getInstitutionsFromSuggestionsAPI(
    action: 'DIVISION_MAPPING' | 'LOCATION_MAPPING' | 'CASE_MAPPING',
    config?: SuggestionConfig,
  ): Observable<Institution[]> {
    return this._http
      .get<unknown[]>('/v2/suggestions/institutions', {
        params: {
          suggestionAction: action,
          filterValue: config?.filterValue ? `%${config.filterValue}%` : '',
          filterFields: 'NAME',
        },
      })
      .pipe(
        map((response) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          response.map((d) => Institution.deserialize(d)!).filter((d) => !!d),
        ),
      );
  }

  getInstitutions(
    target: AssignmentTarget,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Institution[]> {
    if (action) {
      switch (target) {
        case 'division':
          return this._getInstitutionsFromSuggestionsAPI(
            'DIVISION_MAPPING',
            config,
          );
        case 'location':
          return this._getInstitutionsFromSuggestionsAPI(
            'LOCATION_MAPPING',
            config,
          );
        case 'case':
          return this._getInstitutionsFromSuggestionsAPI(
            'CASE_MAPPING',
            config,
          );
      }
    }

    let privileges: PrivilegeName[] = [];
    switch (action) {
      case 'write':
        switch (target) {
          case 'event':
            privileges = ['EVENT_WRITE'];
            break;
          case 'information':
            privileges = ['INFORMATION_WRITE'];
            break;
        }
        break;
      case 'delete':
        switch (target) {
          case 'event':
            privileges = ['EVENT_DELETE'];
            break;
          case 'information':
            privileges = ['INFORMATION_DELETE'];
            break;
        }
    }
    return this._getInstitutionsByPrivilege(privileges, config?.filterValue);
  }
}
