import { Observable } from 'rxjs';
import { Department } from '@tremaze/shared/feature/department/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Division } from '@tremaze/shared/feature/division/types';
import { Pagination } from '@tremaze/shared/models';
import { User, UserTypeName } from '@tremaze/shared/feature/user/types';
import { inject, Injectable } from '@angular/core';
import {
  AssignmentAction,
  AssignmentTarget,
  PaginatedSuggestionConfig,
  SuggestionConfig,
  SuggestionsDataSource,
} from '../data-source';
import { RemoteInstitutionsSuggestionsDataSource } from './institutions';
import { RemoteDivisionsSuggestionsDataSource } from './divisions';
import { RemoteDepartmentsSuggestionsDataSource } from './deparments';
import { RemoteUsersSuggestionsDataSource } from './users';
import { RemoteLocationsSuggestionsDataSource } from './locations';
import { TremazeLocation } from '@tremaze/location-types';
import { RemoteCaseSuggestionsDataSource } from './cases';
import { Case } from '@tremaze/case-types';

@Injectable()
export class RemoteSuggestionsDataSource implements SuggestionsDataSource {
  private readonly _institutionSuggestionsApi = inject(
    RemoteInstitutionsSuggestionsDataSource,
  );
  private readonly _divisionSuggestionsApi = inject(
    RemoteDivisionsSuggestionsDataSource,
  );
  private readonly _departmentsSuggestionsApi = inject(
    RemoteDepartmentsSuggestionsDataSource,
  );
  private readonly _usersSuggestionsApi = inject(
    RemoteUsersSuggestionsDataSource,
  );
  private readonly _locationSuggestionsApi = inject(
    RemoteLocationsSuggestionsDataSource,
  );
  private readonly _caseSuggestionsApi = inject(
    RemoteCaseSuggestionsDataSource,
  );

  getInstitutions(
    target: AssignmentTarget,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Institution[]> {
    return this._institutionSuggestionsApi.getInstitutions(
      target,
      action,
      config,
    );
  }

  getDivisions(
    target: Omit<AssignmentTarget, 'division'>,
    action: AssignmentAction,
    config?: SuggestionConfig,
  ): Observable<Division[]> {
    return this._divisionSuggestionsApi.getDivisions(target, action, config);
  }

  getDepartments(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    instIds?: string[],
  ): Observable<Department[]> {
    return this._departmentsSuggestionsApi.getDepartments(
      target,
      action,
      filter,
      instIds,
    );
  }

  getUsers(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    userTypes?: UserTypeName[],
    instIds?: string[],
  ): Observable<User[]> {
    return this._usersSuggestionsApi.getUsers(
      target,
      action,
      filter,
      userTypes,
      instIds,
    );
  }

  getCases(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    instIds?: string[],
    userIds?: string[],
  ): Observable<Pagination<Case>> {
    return this._caseSuggestionsApi.getCases(
      target,
      action,
      filter,
      instIds,
      userIds,
    );
  }

  getEventResourceViewUserSuggestions(
    config?: PaginatedSuggestionConfig,
  ): Observable<Pagination<User>> {
    return this._usersSuggestionsApi.getPaginatedUsersFromSuggestionsAPI(
      'RESOURCE_VIEW_USERS',
      {
        ...config,
        userTypeIdentifiers: ['EMPLOYEE'],
      },
    );
  }

  getManagedAccountsSuggestions(config?: SuggestionConfig): Observable<User[]> {
    return this._usersSuggestionsApi.getUsersFromSuggestionsAPI(
      'MANAGED_USERS',
      config,
    );
  }

  getLocations(config?: SuggestionConfig): Observable<TremazeLocation[]> {
    return this._locationSuggestionsApi.getLocations();
  }
}

export const provideRemoteSuggestionsDataSource = () => ({
  provide: SuggestionsDataSource,
  useClass: RemoteSuggestionsDataSource,
});
