import { booleanAttribute, Component, HostBinding, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColoredBadgeColor, coloredBadgeColorMap } from './types';

@Component({
  selector: 'tremaze-colored-badge',
  standalone: true,
  imports: [CommonModule],
  template: `
    <span [style.color]="currentColorMap.foreground">
      <ng-content></ng-content>
    </span>
  `,
  styleUrl: './colored-badge.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'colored-badge',
  },
})
export class ColoredBadgeComponent {
  @Input({ required: true }) color: ColoredBadgeColor = 'blue';

  @HostBinding('class.colored-badge--small')
  @Input({ transform: booleanAttribute })
  small = false;

  @HostBinding('class.colored-badge--tiny')
  @Input({ transform: booleanAttribute })
  tiny = false;

  get currentColorMap() {
    return coloredBadgeColorMap[this.color];
  }

  @HostBinding('style.background-color')
  get backgroundColor() {
    return this.currentColorMap.background;
  }

  @HostBinding('style.border-color')
  get borderColor() {
    return this.currentColorMap.foreground;
  }
}
