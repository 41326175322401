import { Component, inject, model, output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadListComponent } from '@tremaze/shared/lazy-load-list';
import { TextSnippetDataSource } from '@tremaze/text-snippets-data-access';
import { TextSnippet } from '@tremaze/text-snippets-types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SharedFeatureInstitutionFeatureFilterButtonModule } from '@tremaze/shared-feature-institution-feature-filter-button';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { FormsModule } from '@angular/forms';
import { toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, merge, skip, tap } from 'rxjs';
import { MatListModule } from '@angular/material/list';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'tremaze-text-snippets-select',
  standalone: true,
  imports: [
    CommonModule,
    LazyLoadListComponent,
    MatListModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInput,
    SharedFeatureInstitutionFeatureFilterButtonModule,
    FormsModule,
  ],
  templateUrl: './text-snippets-select.component.html',
  styleUrl: './text-snippets-select.component.scss',
  host: {
    class: 'text-snippets-select',
  },
})
export class TextSnippetsSelectComponent {
  private readonly _dataSource = inject(TextSnippetDataSource);

  @ViewChild(LazyLoadListComponent)
  private readonly _lazyLoadListComponent?: LazyLoadListComponent<any>;

  readonly snippetSelected = output<TextSnippet>();
  readonly selectedInstitutions = model<Institution[]>([]);
  readonly searchValue = model<string>('');

  private readonly _selectedInstitutions$ = toObservable(
    this.selectedInstitutions,
  );
  private readonly _searchValue$ = toObservable(this.searchValue);

  readonly loadEntries = (page: number, size: number) => {
    return this._dataSource.getTextSnippetsPage({
      filter: {
        page,
        pageSize: size,
        filterFields: ['NAME'],
        filterValue: this.searchValue(),
      },
      instIds: this.selectedInstitutions().map((inst) => inst.id),
    });
  };

  constructor() {
    merge(
      this._selectedInstitutions$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ),
      this._searchValue$.pipe(debounceTime(300), distinctUntilChanged()),
    )
      .pipe(
        skip(2),
        tap(() => this._lazyLoadListComponent?.reload()),
      )
      .subscribe();
  }

  onClickSnippet(snippet: TextSnippet) {
    this._dataSource.getTextSnippet(snippet.id).subscribe((snippet) => {
      this.snippetSelected.emit(snippet);
    });
  }
}
