import { Injectable, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryEditComponent } from './edit.component';
import { DataTableActionsService } from '@tremaze/shared/ui/data-table';
import { Category } from '@tremaze/shared/feature/category/types';

@Injectable({
  providedIn: 'root',
})
export class CategoryEditService extends DataTableActionsService<Category> {
  constructor(private dialog: MatDialog) {
    super();
  }

  edit(
    id: string,
    onSuccess?: (r: Category) => void,
    config?: { viewContainerRef: ViewContainerRef },
  ) {
    this.dialog
      .open(CategoryEditComponent, {
        data: { id },
        viewContainerRef: config?.viewContainerRef,
      })
      .afterClosed()
      .subscribe(onSuccess);
  }

  create(
    initialValue?: Partial<Category>,
    onSuccess?: (r: Category) => void,
    config?: { viewContainerRef: ViewContainerRef },
  ) {
    this.dialog
      .open(CategoryEditComponent, {
        viewContainerRef: config?.viewContainerRef,
      })
      .afterClosed()
      .subscribe(onSuccess);
  }
}
