import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { TextSnippetsSelectComponent } from './text-snippets-select.component';
import { TextSnippet } from '@tremaze/text-snippets-types';

@Component({
  selector: 'tremaze-text-snippet-select.dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButton,
    TextSnippetsSelectComponent,
  ],
  template: `
    <h2 mat-dialog-title>Textbaustein wählen</h2>
    <div mat-dialog-content style="padding-top: 10px">
      <tremaze-text-snippets-select
        (snippetSelected)="onSnippetSelected($event)"
      ></tremaze-text-snippets-select>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Schließen</button>
    </div>
  `,
})
export class TextSnippetSelectDialogComponent {
  private readonly _ref = inject(MatDialogRef);

  onSnippetSelected(snippet: TextSnippet) {
    this._ref.close(snippet);
  }
}
