import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { TextSnippet } from '@tremaze/text-snippets-types';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';

export abstract class TextSnippetDataSource {
  abstract getTextSnippet(id: string): Observable<TextSnippet>;

  abstract getTextSnippetsPageForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<TextSnippet>>;

  abstract getTextSnippetsPage(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<TextSnippet>>;

  abstract deleteTextSnippet(id: string): Observable<void>;

  abstract createTextSnippetForInstitution(
    instId: string,
    textSnippet: Required<Pick<TextSnippet, 'name' | 'text'>>,
  ): Observable<TextSnippet>;

  abstract updateTextSnippet(
    id: string,
    textSnippet: Required<Pick<TextSnippet, 'name' | 'text'>>,
  ): Observable<TextSnippet>;
}
