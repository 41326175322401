import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { map, Observable } from 'rxjs';
import { Department } from '@tremaze/shared/feature/department/types';
import { DefaultDataSourceMethods } from '@tremaze/shared/util-http';
import {
  AssignmentAction,
  AssignmentTarget,
  SuggestionConfig,
  SuggestionsDataSource,
} from '../data-source';
import { remoteSuggestionsApiConfig } from './config';
import { removeUndefined } from '@tremaze/shared/util-utilities';
import { Pagination } from '@tremaze/shared/models';

@Injectable({
  providedIn: 'root',
})
export class RemoteDepartmentsSuggestionsDataSource
  implements Pick<SuggestionsDataSource, 'getDepartments'>
{
  private readonly _http = inject(HttpClient);

  private _getDepartmentsByPrivilege(
    privileges: PrivilegeName[],
    filterValue?: string,
    instIds?: string[],
  ): Observable<Department[]> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'cc/entityPermissions/departments',
      Department.deserialize,
      {
        q: {
          perms: privileges,
          instIds,
        },
        filter: {
          pageSize: remoteSuggestionsApiConfig.pageSize,
          filterValue,
          filterFields: ['NAME'],
          sort: 'name',
          sortDirection: 'asc',
        },
      },
    ).pipe(map((response) => response.content));
  }

  getDepartments(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    instIds?: string[],
  ): Observable<Department[]> {
    let privileges: PrivilegeName[] = [];
    switch (action) {
      case 'write':
        switch (target) {
          case 'event':
            privileges = ['EVENT_WRITE', 'EVENT_DELETE_DEPARTMENT'];
            break;
          case 'information':
            privileges = ['INFORMATION_WRITE', 'INFORMATION_DELETE_DEPARTMENT'];
            break;
          case 'case':
            return this.getDepartmentsFromSuggestionsAPI('CASE_MAPPING', {
              filterValue: filter,
              instIds,
            });
        }
        break;
      case 'delete':
        switch (target) {
          case 'event':
            privileges = ['EVENT_DELETE', 'EVENT_DELETE_DEPARTMENT'];
            break;
          case 'information':
            privileges = [
              'INFORMATION_DELETE',
              'INFORMATION_DELETE_DEPARTMENT',
            ];
            break;
        }
    }
    return this._getDepartmentsByPrivilege(privileges, filter, instIds);
  }

  getDepartmentsFromSuggestionsAPI(
    action: 'CASE_MAPPING',
    config?: SuggestionConfig & { instIds?: string[] },
  ): Observable<Department[]> {
    return this._http
      .get<Pagination<any>>('/v3/suggestions/departments', {
        params: removeUndefined({
          suggestionAction: action,
          filterValue: config?.filterValue ? `%${config.filterValue}%` : '',
          filterFields: 'NAME',
          instIds: config?.instIds?.join(','),
          sort: 'name,asc',
        }),
      })
      .pipe(
        map((response) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          response.content
            .map((d) => Department.deserialize(d)!)
            .filter((d) => !!d),
        ),
      );
  }
}
