import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextEditorComponent } from './rich-text-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, EditorModule, FormsModule],
  declarations: [RichTextEditorComponent],
  exports: [RichTextEditorComponent],
})
export class SharedUiRichTextEditorModule {}
