import { Case, CaseStatisticIdentifier, CaseStatus } from '@tremaze/case-types';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { User } from '@tremaze/shared/feature/user/types';
import { Approval } from '@tremaze/shared/feature/approval/types';
import { CustomFormFillOutResult } from '@tremaze/shared/feature/custom-forms/feature/fill-out';
import { CustomFormSubmission } from '@tremaze/shared/feature/custom-forms/types';
import { CaseInput } from './types';
import { FolderViewDataSource } from '@tremaze/shared/feature/file-storage/data-access';
import { Department } from '@tremaze/shared/feature/department/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { TremazeEvent } from '@tremaze/shared/feature/event/types';

export abstract class CaseDataSource {
  abstract getPaginated(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<Case>>;

  abstract delete(id: string): Observable<void>;

  abstract getFreshById(id: string): Observable<Required<Case>>;

  abstract create(input: CaseInput): Observable<Case>;

  abstract update(id: string, input: CaseInput): Observable<Case>;

  abstract addApprovalToCase(
    caseId: string,
    approvalId: string,
  ): Observable<void>;

  abstract getAvailableStatisticsIdentifiersForInstitution(
    institutionId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatisticIdentifier>>;

  abstract getAvailableCaseStatusesForInstitution(
    institutionId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatus>>;

  abstract getCaseStatuses(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatus>>;

  abstract getAvailableClientsForInstitution(
    instId: string,
    filterValue: string,
  ): Observable<User[]>;

  abstract getAvailableEmployeesForInstitution(
    instId: string,
    filterValue: string,
  ): Observable<User[]>;

  abstract getAvailableApprovals(
    institutionId: string,
    clientId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<Approval>>;

  abstract getAvailableDepartmentsForInstitution(
    institutionId: string,
    filterValue: string,
  ): Observable<Department[]>;

  abstract getCustomFormSubmissionsForCase(
    caseId: string,
  ): Observable<CustomFormSubmission[]>;

  abstract submitCustomFormForCase(
    caseId: string,
    value: CustomFormFillOutResult,
  ): Observable<void>;

  abstract getCaseStatisticIdentifierById(
    id: string,
  ): Observable<CaseStatisticIdentifier>;

  abstract getCaseStatusById(id: string): Observable<CaseStatus>;

  abstract createCaseStatisticIdentifierForInstitution(
    institutionId: string,
    input: Partial<CaseStatisticIdentifier>,
  ): Observable<CaseStatisticIdentifier>;

  abstract createCaseStatusForInstitution(
    institutionId: string,
    input: Partial<CaseStatus>,
  ): Observable<CaseStatus>;

  abstract updateCaseStatisticIdentifier(
    id: string,
    input: Partial<CaseStatisticIdentifier>,
  ): Observable<CaseStatisticIdentifier>;

  abstract updateCaseStatus(
    id: string,
    input: Partial<CaseStatus>,
  ): Observable<CaseStatus>;

  abstract deleteCaseStatisticIdentifier(id: string): Observable<void>;

  abstract deleteCaseStatus(id: string): Observable<void>;

  abstract getHasCaseNumberRangeSettingForInstitution(
    institutionId: string,
  ): Observable<boolean>;

  abstract getEventsForCase(
    caseId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
  ): Observable<TremazeEvent[]>;
}

export abstract class CaseFolderViewDataSource extends FolderViewDataSource {
  abstract setCaseId(caseId: string | null): void;
}
