import {
  DataSourceMethodsCreateOptions,
  DataSourceMethodsDeleteOptions,
  DataSourceMethodsEditOptions,
  DefaultCRUDDataSourceImpl,
  DefaultDataSourceMethods,
  SortedFilteredPaginatedListParams,
} from '@tremaze/shared/util-http';
import {
  CustomForm,
  CustomFormExportSchema,
  CustomFormExportSchemaPlaceholders,
  CustomFormFeature,
  CustomFormSubmission,
} from '@tremaze/shared/feature/custom-forms/types';
import { map, Observable, switchMap, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { toCreateDTO, toEditDTO } from './parser';
import { CustomFormDataSource } from './custom-form-data-source';
import { CustomFormExportPlaceholdersResponse } from './types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { removeUndefined } from '@tremaze/shared/util-utilities';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

type CustomFormCreateOptions = DataSourceMethodsCreateOptions<CustomForm>;

@Injectable({
  providedIn: 'root',
})
export class CustomFormDataSourceImpl
  extends DefaultCRUDDataSourceImpl<CustomForm>
  implements CustomFormDataSource
{
  protected controller = 'forms';

  protected deserializer = CustomForm.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
  ) {
    super();
  }

  getFilteredFeatureForms(
    filterValue: string,
    config?: { feature?: CustomFormFeature; instIds?: string[] },
  ): Observable<CustomForm[]> {
    const { feature, instIds } = config ?? {};
    const filter: SortedFilteredPaginatedListParams = {
      filterValue: filterValue?.trim() ?? '',
      filterFields: ['NAME'],
      page: 0,
      pageSize: 30,
      sort: 'name',
      sortDirection: 'asc',
    };

    if (feature) {
      return DefaultDataSourceMethods.getPaginated<CustomForm>(
        this.http,
        `institutions/forms`,
        CustomForm.deserialize,
        {
          filter,
          instIds,
          q: {
            feature,
          },
        },
      ).pipe(map((d) => d.content));
    }

    return DefaultDataSourceMethods.getPaginated<CustomForm>(
      this.http,
      `forms`,
      CustomForm.deserialize,
      {
        filter,
      },
    ).pipe(map((d) => d.content));
  }

  getAvailablePlaceholdersForForm(
    formId: string,
  ): Observable<CustomFormExportSchemaPlaceholders> {
    return this.http
      .get<CustomFormExportPlaceholdersResponse>(
        `/${this.controller}/${formId}/placeholder`,
      )
      .pipe(
        map((r) => {
          return {
            formFieldPlaceholders: {
              latest: {
                ...r.formFieldPlaceholders.latest,
                formVersionDate: TremazeDate.deserialize(
                  r.formFieldPlaceholders.latest.formVersionDate,
                ),
              },
              other: r.formFieldPlaceholders.other.map((f) => ({
                ...f,
                formVersionDate: TremazeDate.deserialize(f.formVersionDate),
              })),
            },
            otherPlaceholders: r.otherPlaceholders ?? [],
          };
        }),
      );
  }

  setExportSchemaForForm(
    formId: string,
    schema: CustomFormExportSchema | null,
  ): Observable<CustomFormExportSchema | null> {
    return this.http.put<CustomFormExportSchema | null>(
      `/${this.controller}/${formId}/exportSchema`,
      schema,
    );
  }

  getExportSchemaForForm(
    formId: string,
  ): Observable<CustomFormExportSchema | null> {
    return this.http.get<CustomFormExportSchema>(
      `/${this.controller}/${formId}/exportSchema`,
    );
  }

  setPdfExportTemplateFile(
    formId: string,
    fileId: string,
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`/${this.controller}/${formId}/reportTemplate`, null, {
        params: { fileId: fileId ?? '' },
      })
      .pipe(map(() => true));
  }

  getPdfExportTemplateFile(formId: string): Observable<FileStorage | null> {
    return this.http
      .get<FileStorage>(`/${this.controller}/${formId}/reportTemplate`)
      .pipe(map((r) => (r ? FileStorage.deserialize(r) : null)));
  }

  exportFormSubmissions(
    formId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
    institutionIds?: string[],
  ): Observable<Blob> {
    return this.http.get<Blob>(`/${this.controller}/${formId}/export`, {
      params: removeUndefined({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        institutionIds: institutionIds?.join(','),
      }),
      responseType: 'blob' as 'json',
    });
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsDeleteOptions,
  ): Observable<boolean> {
    return this.http
      .put(`/${this.controller}/${id}/archive`, null)
      .pipe(map(() => true));
  }

  create(
    i: CustomForm,
    options?: CustomFormCreateOptions,
  ): Observable<CustomForm> {
    return super.create(toCreateDTO(i) as any, options);
  }

  edit(
    i: CustomForm,
    options?: DataSourceMethodsEditOptions<CustomForm>,
  ): Observable<CustomForm> {
    return super.edit(toEditDTO(i) as any, options);
  }

  getFilledOutFormForUser(
    formId: string,
    userId: string,
  ): Observable<CustomFormSubmission | null> {
    return this.http
      .get(`/${this.controller}/${formId}/${userId}`)
      .pipe(map(CustomFormSubmission.deserialize));
  }

  private _publish(formId: string): Observable<boolean> {
    return this.http.put<boolean>(
      `/${this.controller}/${formId}/publish`,
      null,
    );
  }

  private _unPublish(formId: string): Observable<boolean> {
    return this.http.put<boolean>(
      `/${this.controller}/${formId}/unpublish`,
      null,
    );
  }

  setPublished(formId: string, published: boolean): Observable<boolean> {
    if (published) {
      return this._publish(formId);
    }
    return this._unPublish(formId);
  }

  removeFormFromInstitution(
    formId: string,
    institutionId: string,
  ): Observable<boolean> {
    return this.http
      .put<boolean>(`/${this.controller}/${formId}/removeInstitution`, null, {
        params: { instId: institutionId },
      })
      .pipe(map(() => true));
  }

  setPreviewHTMLonForm(formId: string, html: string) {
    return this.http
      .post(`/${this.controller}/${formId}/html`, html)
      .pipe(map(() => true));
  }

  getPreviewHTMLofForm(formId: string) {
    return this.http
      .get<{ id: string; html: string }>(`/${this.controller}/${formId}/html`)
      .pipe(map((response) => response.html));
  }

  setCSVExportTemplate(formId: string, template: string): Observable<boolean> {
    return this.http
      .post(`/${this.controller}/${formId}/csv`, template)
      .pipe(map(() => true));
  }

  getCSVExportTemplate(formId: string): Observable<string> {
    return this.http
      .get<{ id: string; csv: string }>(`/${this.controller}/${formId}/csv`)
      .pipe(map((response) => response.csv));
  }

  getFormByName(name: string): Observable<CustomForm> {
    return this.getPaginated({
      filter: {
        filterFields: ['NAME'],
        filterValue: name,
        page: 0,
        pageSize: 1,
      },
    }).pipe(
      map((response) => response.content[0]),
      switchMap((f) => this.getFreshById(f.id)),
    );
  }

  getFormsByNames(names: string[]): Observable<CustomForm[]> {
    return zip(...names.map((name) => this.getFormByName(name)));
  }
}

export const provideCustomFormDataSource = () => ({
  provide: CustomFormDataSource,
  useClass: CustomFormDataSourceImpl,
});
