import { map, Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { NumberRangeDataSource } from './number-range-data-source';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NumberRangeDataSourceImpl implements NumberRangeDataSource {
  private readonly _http = inject(HttpClient);

  getNumberRangeSettingForInstitution(institutionId: string): Observable<{
    pattern: string;
    firstNumber: string;
  }> {
    return this._http
      .get<{
        pattern: string;
        firstNumber: string;
      }>(`/institutions/${institutionId}/cases/numberRange`)
      .pipe(map((r) => r ?? null));
  }

  saveNumberRangeSettingForInstitution(
    institutionId: string,
    numberRange: { pattern: string; firstNumber: string },
  ): Observable<void> {
    return this._http.post<void>(
      `/institutions/${institutionId}/cases/numberRange`,
      numberRange,
    );
  }
}

export function provideNumberRangeDataSource() {
  return {
    provide: NumberRangeDataSource,
    useClass: NumberRangeDataSourceImpl,
  };
}
