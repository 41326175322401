import { CaseInput, CaseInputDTO } from './types';
import { CaseStatus } from '@tremaze/case-types';

export function caseInputToCaseInputDTO(caseInput: CaseInput): CaseInputDTO {
  return {
    clientId: caseInput.client.id,
    instId: caseInput.institution.id,
    statusId: caseInput.status.id,
    employeeIds: caseInput.employees.map((employee) => employee.id),
    additionalInfo: caseInput.additionalInfo ?? null,
    startDate: caseInput.startDate?.toISOString() ?? null,
    endDate: caseInput.endDate?.toISOString() ?? null,
    registrationDate: caseInput.registrationDate?.toISOString() ?? null,
    statisticIdentifierId: caseInput.statisticsIdentifier?.id ?? null,
    approvalIds: caseInput.approvals.map((approval) => approval.id),
    departmentIds: caseInput.departments.map((department) => department.id),
  };
}

export function caseStatusToCaseStatusInputDTO(
  caseStatus: Partial<CaseStatus>,
) {
  return {
    name: caseStatus.name,
    color: caseStatus.color?.toUpperCase(),
  };
}
