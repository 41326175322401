import { inject, Injectable } from '@angular/core';
import { CaseDataSource, CaseFolderViewDataSource } from './case-data-source';
import { HttpClient } from '@angular/common/http';
import {
  Case,
  CaseStatisticIdentifier,
  CaseStatus,
  deserializeCaseStatus,
} from '@tremaze/case-types';
import { Approval } from '@tremaze/shared/feature/approval/types';
import { CustomFormFillOutResult } from '@tremaze/shared/feature/custom-forms/feature/fill-out';
import { CustomFormSubmission } from '@tremaze/shared/feature/custom-forms/types';
import { User } from '@tremaze/shared/feature/user/types';
import { Pagination } from '@tremaze/shared/models';
import {
  DataSourceMethodsPaginatedOptions,
  DefaultDataSourceMethods,
} from '@tremaze/shared/util-http';
import { BehaviorSubject, map, Observable, switchMap, take, tap } from 'rxjs';
import { CaseInput } from './types';
import {
  caseInputToCaseInputDTO,
  caseStatusToCaseStatusInputDTO,
} from './helpers';
import { SuggestionsDataSource } from '@tremaze/suggestions-api';
import { ApprovalDataSource } from '@tremaze/approval-data-access';
import { DirStorage } from '@tremaze/shared/feature/file-storage/types';
import { FolderViewDataSourceImpl } from '@tremaze/shared/feature/file-storage/data-access';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import { Department } from '@tremaze/shared/feature/department/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import { TremazeEvent } from '@tremaze/shared/feature/event/types';

@Injectable()
export class CaseDataSourceImpl implements CaseDataSource {
  private readonly _http = inject(HttpClient);
  private readonly _suggestions = inject(SuggestionsDataSource);
  private readonly _approvalDataSource = inject(ApprovalDataSource);

  getPaginated(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<Case>> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'cases',
      Case.deserialize,
      {
        ...options,
        filter: {
          ...(options.filter ?? {}),
          filterFields: ['CASE_NUMBER'],
        },
      },
    );
  }

  delete(id: string): Observable<void> {
    return DefaultDataSourceMethods.deleteById(this._http, 'cases', id).pipe(
      map(() => undefined),
    );
  }

  getFreshById(id: string): Observable<Required<Case>> {
    return DefaultDataSourceMethods.getFreshById(
      this._http,
      'cases',
      Case.deserialize,
      id,
    ) as Observable<Required<Case>>;
  }

  create(input: CaseInput): Observable<Case> {
    const payload = caseInputToCaseInputDTO(input);
    return this._http.post('cases', payload).pipe(map(Case.deserialize));
  }

  update(id: string, input: CaseInput): Observable<Case> {
    const payload = caseInputToCaseInputDTO(input);
    return this._http.put(`cases/${id}`, payload).pipe(map(Case.deserialize));
  }

  addApprovalToCase(caseId: string, approvalId: string): Observable<void> {
    return this.getFreshById(caseId).pipe(
      map((caseInstance) => {
        caseInstance.approvals.push(Approval.deserialize({ id: approvalId }));
        return caseInstance;
      }),
      switchMap((caseInstance) => this.update(caseId, caseInstance)),
      map(() => undefined),
    );
  }

  getAvailableDepartmentsForInstitution(
    institutionId: string,
    filterValue: string,
  ): Observable<Department[]> {
    return this._suggestions.getDepartments('case', 'write', filterValue, [
      institutionId,
    ]);
  }

  getAvailableStatisticsIdentifiersForInstitution(
    institutionId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatisticIdentifier>> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      `institutions/${institutionId}/cases/statisticIdentifiers`,
      (s) => s,
      options,
    );
  }

  getAvailableCaseStatusesForInstitution(
    institutionId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatus>> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      `institutions/${institutionId}/cases/statuses`,
      deserializeCaseStatus,
      options,
    );
  }

  getCaseStatuses(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CaseStatus>> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'cases/statuses',
      deserializeCaseStatus,
      options,
    );
  }

  getAvailableClientsForInstitution(
    instId: string,
    filterValue: string,
  ): Observable<User[]> {
    return this._suggestions.getUsers(
      'case',
      'write',
      filterValue,
      ['USER'],
      [instId],
    );
  }

  getAvailableEmployeesForInstitution(
    instId: string,
    filterValue: string,
  ): Observable<User[]> {
    return this._suggestions.getUsers(
      'case',
      'write',
      filterValue,
      ['EMPLOYEE'],
      [instId],
    );
  }

  getAvailableApprovals(
    institutionId: string,
    clientId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<Approval>> {
    return this._approvalDataSource.getPaginatedApprovals({
      ...options,
      instIds: [institutionId],
      userIds: [clientId],
      includeSubServices: false,
    });
  }

  getCustomFormSubmissionsForCase(
    caseId: string,
  ): Observable<CustomFormSubmission[]> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      `cases/${caseId}/forms/submissions`,
      CustomFormSubmission.deserialize,
    ).pipe(map((p) => p.content));
  }

  submitCustomFormForCase(
    caseId: string,
    value: CustomFormFillOutResult,
  ): Observable<void> {
    return this._http
      .post(`cases/${caseId}/forms/submissions`, value)
      .pipe(map(() => undefined));
  }

  getCaseStatisticIdentifierById(
    id: string,
  ): Observable<CaseStatisticIdentifier> {
    return DefaultDataSourceMethods.getFreshById(
      this._http,
      `cases/statisticIdentifiers`,
      (s) => s,
      id,
    );
  }

  getCaseStatusById(id: string): Observable<CaseStatus> {
    return DefaultDataSourceMethods.getFreshById(
      this._http,
      `cases/statuses`,
      deserializeCaseStatus,
      id,
    );
  }

  createCaseStatisticIdentifierForInstitution(
    institutionId: string,
    input: Partial<CaseStatisticIdentifier>,
  ): Observable<CaseStatisticIdentifier> {
    return this._http.post<CaseStatisticIdentifier>(
      `institutions/${institutionId}/cases/statisticIdentifiers`,
      input,
    );
  }

  createCaseStatusForInstitution(
    institutionId: string,
    input: Partial<CaseStatus>,
  ): Observable<CaseStatus> {
    return this._http
      .post<CaseStatus>(
        `institutions/${institutionId}/cases/statuses`,
        caseStatusToCaseStatusInputDTO(input),
      )
      .pipe(map(deserializeCaseStatus));
  }

  updateCaseStatisticIdentifier(
    id: string,
    input: Partial<CaseStatisticIdentifier>,
  ): Observable<CaseStatisticIdentifier> {
    return this._http
      .put<CaseStatisticIdentifier>(`cases/statisticIdentifiers/${id}`, input)
      .pipe(map((r) => r));
  }

  updateCaseStatus(
    id: string,
    input: Partial<CaseStatus>,
  ): Observable<CaseStatus> {
    return this._http
      .put<CaseStatus>(
        `cases/statuses/${id}`,
        caseStatusToCaseStatusInputDTO(input),
      )
      .pipe(map(deserializeCaseStatus));
  }

  deleteCaseStatisticIdentifier(id: string): Observable<void> {
    return this._http.put<void>(
      `cases/statisticIdentifiers/${id}/archive`,
      null,
    );
  }

  deleteCaseStatus(id: string): Observable<void> {
    return this._http.put<void>(`cases/statuses/${id}/archive`, null);
  }

  getHasCaseNumberRangeSettingForInstitution(
    institutionId: string,
  ): Observable<boolean> {
    return this._http
      .get(`institutions/${institutionId}/cases/numberRange`)
      .pipe(map((r) => !!r));
  }

  getEventsForCase(
    caseId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
  ): Observable<TremazeEvent[]> {
    return this._http
      .get<Record<string, unknown>[]>(`cases/${caseId}/events`, {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      })
      .pipe(map((r) => r.map(TremazeEvent.deserialize) as TremazeEvent[]));
  }
}

@Injectable()
export class CaseFolderViewDataSourceImpl
  extends FolderViewDataSourceImpl
  implements CaseFolderViewDataSource
{
  private _caseId$ = new BehaviorSubject<string | null>(null);

  private _caseRootPath: string | null = null;

  setCaseId(caseId: string | null): void {
    this._caseRootPath = null;
    this._caseId$.next(caseId);
  }

  private _getCaseRootDirectory(caseId: string): Observable<DirStorage> {
    return this._http.get(`cases/${caseId}/dir`).pipe(
      map(DirStorage.deserialize),
      tap((d) => {
        this._caseRootPath = d.absolutePath;
      }),
    );
  }

  override getDirectoryByAbsolutePath(
    path: string,
    cfg?: { skipBasePath: boolean },
  ): Observable<DirStorage> {
    if (
      this._caseRootPath &&
      this._caseRootPath.startsWith(path) &&
      !path.startsWith(this._caseRootPath)
    ) {
      path = '';
    }

    if (path === '' || path === '/') {
      return this._caseId$.pipe(
        filterNotNullOrUndefined(),
        take(1),
        switchMap((caseId) => this._getCaseRootDirectory(caseId)),
      );
    }

    return super.getDirectoryByAbsolutePath(path, cfg);
  }
}

export const provideCaseDataSources = () => {
  return [
    {
      provide: CaseDataSource,
      useClass: CaseDataSourceImpl,
    },
    {
      provide: CaseFolderViewDataSource,
      useClass: CaseFolderViewDataSourceImpl,
    },
  ];
};
