import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CustomForm,
  CustomFormFeature,
} from '@tremaze/shared/feature/custom-forms/types';
import { CustomFormDataSource } from '@tremaze/shared/feature/custom-forms/data-access';

@Injectable({
  providedIn: 'root',
})
export class CustomFormSelectionDataAccessService {
  private readonly _dataSource = inject(CustomFormDataSource);

  getFilteredCustomForms(
    filterValue: string,
    config?: { feature?: CustomFormFeature; instIds?: string[] },
  ): Observable<CustomForm[]> {
    return this._dataSource.getFilteredFeatureForms(filterValue, config);
  }
}
