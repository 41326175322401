import { DataTableActionsService } from '@tremaze/shared/ui/data-table';
import { inject, Injectable } from '@angular/core';
import { EventTemplate } from '@tremaze/shared/feature/event/types';
import { EventTemplateEditService } from '@tremaze/shared/feature/event/template/feature/edit';
import { filter, switchMap, tap } from 'rxjs';
import { DefaultRemoteEventTemplateDataSource } from '@tremaze/shared/feature/event/template/data-access';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';

@Injectable()
export class EventTemplateDataTableActionsService
  implements DataTableActionsService<EventTemplate>
{
  private readonly _editService = inject(EventTemplateEditService);
  private readonly _dataSource = inject(DefaultRemoteEventTemplateDataSource);
  private readonly _confirmationService = inject(ConfirmationService);

  create(
    initialValue?: Partial<EventTemplate>,
    onSuccess?: (r: EventTemplate) => void,
  ) {
    this._editService
      .createItem()
      .pipe(tap((r) => r?.item && onSuccess && onSuccess(r.item)))
      .subscribe();
  }

  edit(id: string, onSuccess?: (r: EventTemplate) => void) {
    this._dataSource
      .getFreshById(id)
      .pipe(
        switchMap((template) => this._editService.editItem(template)),
        tap((r) => r?.item && onSuccess && onSuccess(r.item)),
      )
      .subscribe();
  }

  delete(id: string, onSuccess?: () => void) {
    this._confirmationService
      .askUserForConfirmation({ warn: true })
      .pipe(
        filter((r) => r?.confirmed),
        switchMap(() => this._editService.deleteItem(id)),
      )
      .subscribe((r) => r && onSuccess && onSuccess());
  }
}
