import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AssignmentAction,
  AssignmentTarget,
  SuggestionsDataSource,
} from '../data-source';
import { Case } from '@tremaze/case-types';
import { Pagination } from '@tremaze/shared/models';
import { Observable } from 'rxjs';
import { DefaultDataSourceMethods } from '@tremaze/shared/util-http';
import { removeUndefined } from '@tremaze/shared/util-utilities';
import { remoteSuggestionsApiConfig } from './config';

@Injectable({
  providedIn: 'root',
})
export class RemoteCaseSuggestionsDataSource
  implements Pick<SuggestionsDataSource, 'getCases'>
{
  private readonly _http = inject(HttpClient);

  getCases(
    target: AssignmentTarget,
    action: AssignmentAction,
    filter?: string,
    instIds?: string[],
    userIds?: string[],
  ): Observable<Pagination<Case>> {
    if (target === 'event') {
      if (action === 'write') {
        return this._getCasesFromSuggestionsApi(
          'EVENT_MAPPING',
          filter,
          instIds,
          userIds,
        );
      }
    }

    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'case',
      Case.deserialize,
      {
        filter: {
          filterValue: filter,
          filterFields: ['CASE_NUMBER'],
          pageSize: remoteSuggestionsApiConfig.pageSize,
          sort: 'caseNumber',
          sortDirection: 'asc',
        },
        q: removeUndefined({ instIds, userIds }),
      },
    );
  }

  private _getCasesFromSuggestionsApi(
    suggestionAction: 'EVENT_MAPPING',
    filter?: string,
    instIds?: string[],
    userIds?: string[],
  ): Observable<Pagination<Case>> {
    return DefaultDataSourceMethods.getPaginated(
      this._http,
      'v3/suggestions/cases',
      Case.deserialize,
      {
        filter: {
          filterValue: filter,
          filterFields: ['CASE_NUMBER'],
          pageSize: remoteSuggestionsApiConfig.pageSize,
          sort: 'caseNumber',
          sortDirection: 'asc',
        },
        q: removeUndefined({
          instIds,
          userId: userIds?.length ? userIds[0] : undefined,
          suggestionAction,
        }),
      },
    );
  }
}
