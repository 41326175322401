import {
  AfterViewInit,
  Component,
  EventEmitter,
  input,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PrivilegeName } from '@tremaze/shared/permission/types';
import { DataTableStore } from '../data-table.store';
import { IdObject } from '@tremaze/shared/util/id-object';
import { distinctUntilChanged, Subject } from 'rxjs';
import { DataTableComponent } from '../data-table.component';
import { ExpandingIconTextInputComponent } from '@tremaze/shared/ui/inputs/expanding-icon-text-input';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import {
  PersonalConfigDepartment,
  PersonalConfigInstitution,
  PersonalConfigUser,
} from '@tremaze/personal-config';
import { InstitutionFilterButtonComponent } from '@tremaze/shared-feature-institution-feature-filter-button';
import { UserFilterButtonComponent } from '@tremaze/shared-feature-user-feature-filter-button';
import { DepartmentFilterButtonComponent } from '@tremaze/shared/feature/department/feature/filter-button';

@Component({
  selector: 'tremaze-data-table-search-bar',
  templateUrl: './data-table-search-bar.component.html',
  styleUrls: ['./data-table-search-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DataTableSearchBarComponent<T extends IdObject>
  implements AfterViewInit, OnDestroy
{
  get filterConfig(): FilterConfig {
    return this._filterConfig;
  }

  @Input() set filterConfig(value: FilterConfig) {
    this._filterConfig = value;
  }

  readonly hideSearchBar = input<boolean>(false);

  private _filterConfig: FilterConfig;
  @ViewChild(InstitutionFilterButtonComponent)
  private readonly _institutionFilterButtonComponent?: InstitutionFilterButtonComponent;
  @ViewChild(DepartmentFilterButtonComponent)
  private readonly _departmentFilterButtonComponent?: DepartmentFilterButtonComponent;
  @ViewChild(UserFilterButtonComponent)
  private readonly _userFilterButtonComponent?: UserFilterButtonComponent;
  @ViewChild('filterInput', { read: ExpandingIconTextInputComponent })
  private readonly _filterInput?: ExpandingIconTextInputComponent;
  private readonly _destroyed$ = new Subject<void>();

  @Output() readonly filterReset = new EventEmitter<void>();

  constructor(
    readonly store: DataTableStore<T>,
    readonly host: DataTableComponent<any>,
  ) {}

  get enableInstitutionFilter(): boolean {
    return this._filterConfig.enableInstitutionFilter;
  }

  get enableDepartmentFilter(): boolean {
    return this._filterConfig.enableDepartmentFilter;
  }

  get enableUserFilter(): boolean {
    return this._filterConfig.enableUserFilter;
  }

  get showAnyFilter(): boolean {
    return (
      this.enableInstitutionFilter ||
      this.enableDepartmentFilter ||
      this.enableUserFilter ||
      !!this.host.customFilterDefinitions?.length
    );
  }

  get hideFilter(): boolean {
    return this._filterConfig.hideFilter;
  }

  get filterFields(): string[] {
    return this._filterConfig.filterFields;
  }

  get institutionFilterPrivilege(): PrivilegeName[] {
    return this._filterConfig.institutionFilterPrivilege;
  }

  get departmentFilterPrivilege(): PrivilegeName[] {
    return this._filterConfig.departmentFilterPrivilege;
  }

  get userFilterPrivilege(): PrivilegeName[] {
    return this._filterConfig.userFilterPrivilege;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  ngAfterViewInit(): void {
    if (!this._filterConfig.hideFilter && this._filterInput) {
      this._filterInput.valueChange
        .pipe(
          takeUntil(this._destroyed$),
          debounceTime(500),
          map((value) => (!(value as string)?.length ? null : value)),
          distinctUntilChanged(),
          tap((filterValue) =>
            this.store.filterUpdate({
              filterFields: this.filterFields,
              filterValue: filterValue as string,
            }),
          ),
        )
        .subscribe();
    }
  }

  onInstitutionFilterChange(institutions: PersonalConfigInstitution[]): void {
    this.store.institutionFilterUpdate(institutions);
  }

  onDepartmentFilterChange(departments: PersonalConfigDepartment[]): void {
    this.store.departmentFilterUpdate(departments);
  }

  onUserFilterChange(users: PersonalConfigUser[]): void {
    this.store.userFilterUpdate(users);
  }

  onClickReloadButton(): void {
    this.store.reload();
  }

  onClickResetFiltersButton(): void {
    this._institutionFilterButtonComponent?.resetFilter();
    this._departmentFilterButtonComponent?.resetFilter();
    this._userFilterButtonComponent?.resetFilter();
    this._filterInput.value = '';
    this._filterInput.valueChange.emit('');
    this.filterReset.emit();
  }
}

export interface FilterConfig {
  hideFilter?: boolean;
  filterFields?: string[];
  enableInstitutionFilter?: boolean;
  enableDepartmentFilter?: boolean;
  enableUserFilter?: boolean;
  institutionFilterPrivilege?: PrivilegeName[];
  departmentFilterPrivilege?: PrivilegeName[];
  userFilterPrivilege?: PrivilegeName[];
}
