<div class="text-snippet-select__Head">
  <mat-form-field appearance="outline">
    <mat-label>Suche</mat-label>
    <input matInput [(ngModel)]="searchValue" />
  </mat-form-field>

  <tremaze-institution-filter-button style="display: block"
                                     [(ngModel)]="selectedInstitutions"></tremaze-institution-filter-button>
</div>

<tremaze-lazy-load-list [loadEntriesPage]="loadEntries" gap="10px" padding="10px">

  <ng-template let-entry>

    <mat-list-item matRipple (click)="onClickSnippet(entry)" class="text-snippet-select__List-Item">
      <span matListItemTitle>{{entry.name}}</span>
    </mat-list-item>

  </ng-template>

</tremaze-lazy-load-list>
