import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { NgControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Failure } from '@tremaze/shared/util-error';
import { ControlValueAccessor } from '@ngneat/reactive-forms';

@Component({
  selector: 'tremaze-selector',
  template: `
    <mat-select
      [compareWith]="comparator"
      [disabled]="disabled"
      [multiple]="multiple"
      [value]="value"
      [required]="required"
      [placeholder]="placeholder"
      aria-autocomplete="none"
    >
      <mat-option [disabled]="noResetOption || multiple">{{
        placeholder
      }}</mat-option>
      <mat-option (click)="onClickAddOption($event)" *ngIf="withAddOption"
        >Eintrag hinzufügen
      </mat-option>
      <mat-option *ngFor="let item of data" [value]="item">{{
        getOptionLabel(item)
      }}</mat-option>
    </mat-select>
  `,
  styles: [],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [{ provide: MatFormFieldControl, useExisting: SelectorComponent }],
})
export class SelectorComponent<T>
  implements
    OnInit,
    OnDestroy,
    DoCheck,
    MatFormFieldControl<T | T[]>,
    ControlValueAccessor<T | T[]>
{
  static nextId = 0;
  data: T[] = [];
  error = false;
  errorState = false;
  controlType?: string;
  autofilled?: boolean;
  stateChanges = new Subject<void>();
  defaultPlaceholder: string;
  @HostBinding() id = `generic-selector-${SelectorComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Input() dataSource: () => Observable<Failure | T[]>;
  @Output() selectionChange = new EventEmitter<T | T[]>();
  @Output() clickedAddOption = new EventEmitter();
  @ViewChild(MatSelect, { static: true })
  private _input: MatSelect;
  private _reloadSubject = new Subject();

  constructor(
    public elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private cdRef: ChangeDetectorRef,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _noResetOption: boolean;

  @Input()
  get noResetOption(): boolean {
    return this._noResetOption;
  }

  set noResetOption(value: boolean) {
    this._noResetOption = coerceBooleanProperty(value);
  }

  private _withAddOption = false;

  @Input()
  get withAddOption(): boolean {
    return this._withAddOption;
  }

  set withAddOption(value: boolean) {
    this._withAddOption = coerceBooleanProperty(value);
  }

  get shouldLabelFloat() {
    return !this.empty || this._input.shouldLabelFloat;
  }

  get empty() {
    if (this.value instanceof Array) {
      return !this.value.length;
    }
    return this.value === null || this.value === undefined;
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next(null);
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _multiple = false;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }

  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
    this.stateChanges.next(null);
  }

  private _value: T | T[] | null;

  get value() {
    return this._value;
  }

  @Input()
  set value(v: T | T[] | null) {
    if (v === undefined) {
      v = null;
    }
    this._value = v;
    this.stateChanges.next(null);
    this.onChangeCallback(this.value);
  }

  private _focused = false;

  get focused(): boolean {
    return this._focused && !this.disabled;
  }

  set focused(value: boolean) {
    this._focused = value;
  }

  reload() {
    this._reloadSubject.next(null);
  }

  @Input() getOptionLabel: (val: T) => string = (val) => (val as any).name;

  @Input() comparator: (val1: T, val2: T) => boolean = (v1, v2) =>
    (v1 as any)?.id === (v2 as any)?.id;

  onContainerClick(event: MouseEvent): void {
    this._input.onContainerClick();
  }

  onClickAddOption(ev: MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    this.clickedAddOption.emit();
  }

  ngOnInit() {
    if (!this.dataSource) {
      throw new Error(
        'Selector must be provided with either a SelectorRepository via Dependency Injector or a dataSource Input',
      );
    }
    this.placeholder = this.defaultPlaceholder || 'Bitte auswählen';
    if (this._input instanceof MatSelect) {
      this._input.setDisabledState(this.disabled);
      this._input.selectionChange.subscribe((r: MatSelectChange) => {
        this.value = r.value;
        this.selectionChange.emit(this.value);
      });
      this.fm
        .monitor(this._input._elementRef?.nativeElement, true)
        .subscribe((origin) => {
          this._focused = !!origin;
          this.stateChanges.next(null);
        });
    }
    this._reloadSubject.subscribe(() => this.loadData());
    this.loadData();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._reloadSubject.complete();
    this.fm.stopMonitoring(this._input._elementRef?.nativeElement);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (this._value !== obj) {
      this.value = obj;
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  private onTouchedCallback: () => void = () => {};

  private onChangeCallback: (_: any) => void = () => {};

  private loadData() {
    const r$ = this.dataSource;
    if (r$) {
      r$().subscribe((r) => {
        if (r instanceof Failure) {
          this.error = true;
        } else {
          this.error = false;
          this.data = r;
          this.cdRef.detectChanges();
        }
      });
    }
  }
}
