import { Pipe, PipeTransform } from '@angular/core';
import { ColoredBadgeColor } from './colored-badge/types';

@Pipe({
  name: 'colorBadge',
  standalone: true,
})
export class ColorBadgePipe implements PipeTransform {
  transform(value: ColoredBadgeColor): string {
    switch (value) {
      case 'blue':
        return 'Blau';
      case 'green':
        return 'Grün';
      case 'orange':
        return 'Orange';
      case 'cyan':
        return 'Cyan';
      case 'purple':
        return 'Lila';
      case 'yellow':
        return 'Gelb';
      case 'wine':
        return 'Wein';
      case 'red':
        return 'Rot';
      case 'gray':
        return 'Grau';
      default:
        return '';
    }
  }
}
